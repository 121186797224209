import { css } from 'styled-components';

import type { ITheme } from '../../themes';

import {
    Status,
    BORDER_TYPE,
    TEXTAREA_PADDING,
    TEXTAREA_PADDING_ACTIVE,
    TEXTAREA_SIZE,
    BORDER_RADIUS,
} from './constants';

export const getBorderBottom = (
    theme: ITheme,
    status: Status,
    focused?: boolean,
    disabled?: boolean,
    readOnly?: boolean
) => {
    return css`
        ${readOnly || (!focused && status === 'default') ? '1px' : '2px'}
        ${BORDER_TYPE} ${getBorderColorInput(theme, status, focused, disabled, readOnly)}
    `;
};

export const getTextareaPadding = (status: Status, focused?: boolean, readOnly?: boolean) => {
    return readOnly || (!focused && status === 'default')
        ? TEXTAREA_PADDING
        : TEXTAREA_PADDING_ACTIVE;
};

export const getLabelColorByStatus = (theme: ITheme, status: Status, defaultValue = 'inherit') => {
    switch (status) {
        case 'error':
            return theme.color.danger[60];
        case 'success':
            return theme.color.success[50];
        default:
            return defaultValue;
    }
};

export const getBorderColorInput = (
    theme: ITheme,
    status: Status,
    focused?: boolean,
    disabled?: boolean,
    readOnly?: boolean
) => {
    if (disabled || readOnly) return theme.color.neutral[10];
    if (status === 'success') return theme.color.success[50];
    if (status === 'error') return theme.color.danger[60];
    if (status === 'default' && focused) return theme.color.primary[60];

    return theme.color.neutral[20];
};

export const getTextColor = (theme: ITheme, focused?: boolean, disabled?: boolean) => {
    if (disabled) return theme.color.neutral[30];
    if (focused) return theme.color.neutral[90];
    return theme.color.neutral[50];
};
