import styled, { css } from 'styled-components';

import { getDefaultIconStyled, IStyledIconDefaultProps } from '../common';
import { DEFAULT_THEME } from '../../../common';

export const StyledSearch = styled.div<IStyledIconDefaultProps>`
    ${({ theme, disabled }) => css`
        display: flex;
        align-items: center;
        & svg {
            ${getDefaultIconStyled(theme, disabled)}
            cursor: default;
            &:hover {
                fill: ${disabled ? theme.color.neutral[30] : theme.color.primary[50]};
            }
        }
    `}
`;

StyledSearch.defaultProps = {
    theme: DEFAULT_THEME,
};
