import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../../../common';

export const DropzoneIcon = styled.div`
    ${({ theme }) => css`
        & svg {
            fill: ${theme.color.primary[60]};
            height: 24px;
            width: 24px;
        }
    `}
`;

DropzoneIcon.defaultProps = {
    theme: DEFAULT_THEME,
};
