import React from 'react';

import { ReactComponent as SearchLight } from '../../svg/light/search.svg';
import { ReactComponent as SearchRegular } from '../../svg/regular/search.svg';
import { ReactComponent as SearchSolid } from '../../svg/solid/search.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: SearchLight,
    regular: SearchRegular,
    solid: SearchSolid,
};

export const Search: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
