import React from 'react';

import { ReactComponent as CheckLight } from '../../svg/light/check.svg';
import { ReactComponent as CheckCircleLight } from '../../svg/light/check-circle.svg';
import { ReactComponent as CheckSquareLight } from '../../svg/light/check-square.svg';
import { ReactComponent as CheckRegular } from '../../svg/regular/check.svg';
import { ReactComponent as CheckCircleRegular } from '../../svg/regular/check-circle.svg';
import { ReactComponent as CheckSquareRegular } from '../../svg/regular/check-square.svg';
import { ReactComponent as CheckSolid } from '../../svg/solid/check.svg';
import { ReactComponent as CheckCircleSolid } from '../../svg/solid/check-circle.svg';
import { ReactComponent as CheckSquareSolid } from '../../svg/solid/check-square.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: CheckLight,
        regular: CheckRegular,
        solid: CheckSolid,
    },
    circle: {
        light: CheckCircleLight,
        regular: CheckCircleRegular,
        solid: CheckCircleSolid,
    },
    square: {
        light: CheckSquareLight,
        regular: CheckSquareRegular,
        solid: CheckSquareSolid,
    },
};

export const Check: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
