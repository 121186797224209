import styled, { css } from 'styled-components';
import { EyeSlash } from '@legex/icons';
import React from 'react';

import { getDefaultIconStyled, IStyledIconDefaultProps } from '../common';
import { DEFAULT_THEME } from '../../../common';

const EyeClosed = (props) => {
    return <EyeSlash weight="light" {...props} />;
};

export const StyledEyeCloseOutline = styled(EyeClosed)<IStyledIconDefaultProps>`
    ${({ theme, disabled }) => css`
        ${getDefaultIconStyled(theme, disabled)}
    `}
`;

StyledEyeCloseOutline.defaultProps = {
    theme: DEFAULT_THEME,
};
