import styled from 'styled-components';

import type { Size } from '../../../common';

export interface IControlWrapperProps {
    size: Size;
}

export const ControlWrapper = styled.div<IControlWrapperProps>`
    display: flex;
    width: 100%;
    height: ${({ size }) => size}px;
`;
