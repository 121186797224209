import styled from 'styled-components';

export const StyledIndicatorsContainer = styled.div`
    align-self: stretch;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    align-items: baseline;
    background: transparent;
    border-color: transparent;
`;
