export const INPUT_HEIGHT = {
    xs: 55,
    xl: 80,
};
export const BORDER_DADIUS = 3;
export const INPUT_WIDTH = 200;
export const MENU_HEIGHT = 208;
export const MENU_WIDTH = 128;
export const MENU_LEFT_RIGHT_BORDER_PADDING = 16;
export const MENU_TOP_BOTTOM_BORDER_PADDING = 8;

export const DROPDOWN_OFFSET = INPUT_HEIGHT;
