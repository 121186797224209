import React from 'react';

import { ReactComponent as GlobeAmericaLight } from '../../svg/light/globe-americas.svg';
import { ReactComponent as GlobeAmericaRegular } from '../../svg/regular/globe-americas.svg';
import { ReactComponent as GlobeAmericaSolid } from '../../svg/solid/globe-americas.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: GlobeAmericaLight,
    regular: GlobeAmericaRegular,
    solid: GlobeAmericaSolid,
};

export const GlobeAmerica: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
