import React from 'react';

import { ReactComponent as ChevronRightLight } from '../../svg/light/chevron-right.svg';
import { ReactComponent as ChevronRightCircleLight } from '../../svg/light/chevron-circle-right.svg';
import { ReactComponent as ChevronRightSquareLight } from '../../svg/light/chevron-square-right.svg';
import { ReactComponent as ChevronRightRegular } from '../../svg/regular/chevron-right.svg';
import { ReactComponent as ChevronRightCircleRegular } from '../../svg/regular/chevron-circle-right.svg';
import { ReactComponent as ChevronRightSquareRegular } from '../../svg/regular/chevron-square-right.svg';
import { ReactComponent as ChevronRightSolid } from '../../svg/solid/chevron-right.svg';
import { ReactComponent as ChevronRightCircleSolid } from '../../svg/solid/chevron-circle-right.svg';
import { ReactComponent as ChevronRightSquareSolid } from '../../svg/solid/chevron-square-right.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: ChevronRightLight,
        regular: ChevronRightRegular,
        solid: ChevronRightSolid,
    },
    circle: {
        light: ChevronRightCircleLight,
        regular: ChevronRightCircleRegular,
        solid: ChevronRightCircleSolid,
    },
    square: {
        light: ChevronRightSquareLight,
        regular: ChevronRightSquareRegular,
        solid: ChevronRightSquareSolid,
    },
};

export const ChevronRight: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
