import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';
import type { ITheme } from '../../themes';

import { SwitchSize, SwitchSlider } from './SwitchSlider';
import { SwitchWrapper } from './SwitchWrapper';
import {
    OUTLINE_BORDER_RADIUS_XL,
    OUTLINE_BORDER_RADIUS_XS,
    OUTLINE_OFFSET,
    OUTLINE_SIZE,
} from './constants';

interface ISwitchComponentProps {
    disabled: boolean;
    checked: boolean;
    size: SwitchSize;
}

const commonSwitchComponentStyles = (
    theme: ITheme,
    type: 'Focus' | 'Hover' | 'Pressed',
    disabled: boolean
) => css`
    &:after {
        background-color: ${disabled ? 'transparent' : theme.color.opacity['black' + type]};
    }
`;

export const SwitchComponent = styled.div<ISwitchComponentProps>`
    display: flex;
    align-items: center;
    ${({ disabled, theme }) => css`
        cursor: ${disabled ? 'default' : 'pointer'};
        color: ${disabled ? theme.color.neutral[30] : theme.color.neutral[90]};
    `}
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    &:focus {
        outline: none;
    }

    ${({ theme, disabled, checked, size }) =>
        !disabled &&
        css`
            &:focus > ${SwitchWrapper} > ${SwitchSlider} {
                ::after {
                    outline: none;
                    content: '';
                    display: block;
                    position: absolute;
                    top: ${OUTLINE_OFFSET}px;
                    bottom: ${OUTLINE_OFFSET}px;
                    left: ${OUTLINE_OFFSET}px;
                    right: ${OUTLINE_OFFSET}px;
                    border-radius: ${size === 'xl'
                        ? OUTLINE_BORDER_RADIUS_XL
                        : OUTLINE_BORDER_RADIUS_XS}px;
                    border: ${OUTLINE_SIZE}px solid ${theme.color.primary[30]};
                }
            }
            &:hover {
                & > ${SwitchWrapper} > ${SwitchSlider} {
                    border-color: ${theme.color[checked ? 'primary' : 'neutral'][60]};
                    &::before {
                        background-color: ${theme.color[checked ? 'primary' : 'neutral'][60]};
                    }
                }
            }
            &:active > ${SwitchWrapper} > ${SwitchSlider} {
                & > ${SwitchWrapper} > ${SwitchSlider} {
                    border-color: ${theme.color[checked ? 'primary' : 'neutral'][70]};
                    &::before {
                        background-color: ${theme.color[checked ? 'primary' : 'neutral'][70]};
                    }
                }
            }
        `}
`;
/*

&:hover {
    ${Box} {
        border-color: ${theme.color[checked ? 'primary' : 'neutral'][60]};

        ${Mark} {
            background-color: ${theme.color[
                                      checked ? 'primary' : 'neutral'
                                  ][60]};
        }
    }
}

&:active {
    ${Box} {
        border-color: ${theme.color[checked ? 'primary' : 'neutral'][70]};

        ${Mark} {
            background-color: ${theme.color[
                                      checked ? 'primary' : 'neutral'
                                  ][70]};
        }
    }
}

&:focus {
    ${Box}:after {
        outline: none;
        content: '';
        display: block;
        position: absolute;
        top: ${OUTLINE_OFFSET}px;
        bottom: ${OUTLINE_OFFSET}px;
        left: ${OUTLINE_OFFSET}px;
        right: ${OUTLINE_OFFSET}px;
        border-radius: ${OUTLINE_BORDER_RADIUS}px;
        border: ${OUTLINE_SIZE}px solid ${theme.color.primary[30]};
    }
} */

SwitchComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
