import { ICON_SIZE_XL, ICON_SIZE_XS } from '../../constants';

import type { Size } from './../types';

/**
 * Возвращает размер иконки
 * @param {Size} size - Размер инпута.
 * @return {20 | 24 } - Размер иконки
 */

export const getIconSize = (size: Size): 20 | 24 => {
    return 'xs' === size ? ICON_SIZE_XS : ICON_SIZE_XL;
};
