import type { ITheme } from '../../../themes';
import {
    INPUT_HEIGHT_XL,
    INPUT_HEIGHT_XS,
    SUFFIX_PADDING_XL,
    SUFFIX_PADDING_XS,
    COINS_PADDING_XL,
    COINS_PADDING_XS,
    INPUT_BORDER_RADIUS,
} from '../constants';
import type { Status, Size } from '../common';

export const getAdditionalTextColor = (
    status: Status,
    theme: ITheme,
    disabled = false,
    focused = false
) => {
    if (status === 'error') return theme.color.danger[50];

    if (status === 'success') return theme.color.success[50];

    return getTextColor(focused, disabled, theme);
};

export const getBorderColor = (
    status: Status,
    focused = false,
    disabled = false,
    theme: ITheme
) => {
    if (disabled) return theme.color.neutral[10];

    if (status === 'success' && !focused) return theme.color.success[50];

    if (status === 'error') return theme.color.danger[50];

    if (status === 'default' && focused) return theme.color.primary[30];

    return theme.color.neutral[40];
};

export const getTextColor = (focused: boolean, disabled = false, theme: ITheme) => {
    if (disabled) return theme.color.neutral[30];

    if (focused) return theme.color.neutral[90];

    return theme.color.neutral[50];
};

export const getInputHeight = (size: Size) => {
    switch (size) {
        case 'xl':
            return INPUT_HEIGHT_XL;
        case 'xs':
            return INPUT_HEIGHT_XS;
    }
};

export const getSuffixPadding = (size: Size) => {
    switch (size) {
        case 'xl':
            return SUFFIX_PADDING_XL;
        case 'xs':
            return SUFFIX_PADDING_XS;
    }
};

export const getCoinsPadding = (size: Size) => {
    switch (size) {
        case 'xl':
            return COINS_PADDING_XL;
        case 'xs':
            return COINS_PADDING_XS;
    }
};
