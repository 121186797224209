import styled, { css } from 'styled-components';

import { Text2 } from '../../Typography';

import { ERROR_MESSAGE_WIDTH } from './constants';

export const ErrorMessage = styled(Text2)`
    ${({ theme }) => css`
        position: absolute;
        left: 0;
        width: ${ERROR_MESSAGE_WIDTH}px;
        color: ${theme.color.danger[60]};
    `}
`;
