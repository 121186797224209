import React from 'react';
import type { FC } from 'react';

import { ReactComponent as SpinnerIcon } from './svg/Spinner.svg';
import { SpinnerIconWrapper } from './SpinnerIconWrapper';
import type { Size } from './SpinnerIconWrapper';

export * from './Overlay';

export interface ISpinnerProps {
    /** Имя класса для переопределения стилей */
    className?: string;
    /** data-test-id атрибут для тестирования компонента */
    dataTestId?: string;
    /** Размер компонента */
    size?: Size;
}

export const Spinner: FC<ISpinnerProps> = ({ className, dataTestId, size = 'xl' }) => {
    return (
        <SpinnerIconWrapper className={className} data-test-id={dataTestId} size={size}>
            <SpinnerIcon />
        </SpinnerIconWrapper>
    );
};
