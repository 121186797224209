import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { BORDER_RADIUS, CalendarSize, HEADER_PADDINGS } from './constants';

export const HeaderComponent = styled.div<{ size: CalendarSize }>`
    ${({ theme, size }) => css`
        background-color: ${theme.color.primary[60]};
        color: ${theme.color.neutral[0]};
        border-radius: ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0;
        padding: ${HEADER_PADDINGS[size]};
        box-shadow: ${theme.shadow[5]};
    `}
`;

HeaderComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
