import styled, { css } from 'styled-components';

import type { Size } from '../../../common';
import { OPTION_FONT_SIZE, OPTION_SIZE, OPTION_HORIZONTAL_PADDING } from '../../constants';
import { DEFAULT_THEME } from '../../../../../common';
import { Text1 } from '../../../../../Typography';

export interface IOptionWrapperProps {
    size: Size;
    disabled?: boolean;
    selected?: boolean;
    focused?: boolean;
}

export const OptionText = styled(Text1)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const OptionWrapper = styled.div<IOptionWrapperProps>`
    ${({ size, theme, disabled, selected, focused }) => css`
        display: flex;
        align-items: center;
        padding: 0px ${OPTION_HORIZONTAL_PADDING}px;
        height: ${OPTION_SIZE[size]}px;
        & > div {
            font-size: ${OPTION_FONT_SIZE[size]}px;
        }
        background-color: ${theme.color.neutral[0]};
        color: ${theme.color.neutral[90]};
        cursor: ${disabled ? 'default' : 'pointer'};
        ${(selected || disabled) &&
        css`
            background-color: ${theme.color.neutral[0]};
            color: ${theme.color.neutral[30]};
        `}
        ${focused &&
        css`
            color: ${theme.color.primary[60]};
            background-color: ${theme.color.neutral[5]};
        `}
    ${!disabled &&
        css`
            &:hover {
                color: ${theme.color.primary[60]};
                background-color: ${theme.color.neutral[5]};
            }
        `}
    /* width of option's content */
    & > div {
            width: 100%;
        }
    `};
`;

OptionWrapper.defaultProps = {
    theme: DEFAULT_THEME,
};
