import React, { useState, useEffect, FC } from 'react';
import { Transition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import { Times } from '@legex/icons';

import { useGesture } from '../../common/hooks';
import { useMobile } from '../../common/hooks/useMobile';

import type { INotificationProps } from './NotificationComponent';
import { NOTIFICATION_ICONS } from './NotificationComponent';
import { DynamicNotificationComponent } from './DynamicNotificationComponent';
import { IconWrapper } from './IconWrapper';
import { IconClose } from './IconClose';
import { DEFAULT_DURATION } from './constants';
import { getTransitionStyles } from './utils';


export type VisibilityHandler = () => void;

export interface IDynamicNotificationProps extends INotificationProps {
    /** Время отображения анимации открытия/закрытия */
    duration?: number;
    /** Время отображения оповещения до автоматического закрытия */
    delay?: number;
    /** Контейнер, в котором происходит размещение оповещения */
    container?: Element;
    /** Коллбэк начала анимации отображения оповещения */
    onShow?: VisibilityHandler;
    /** Коллбэк окончания анимации скрытия оповещения */
    onHide?: VisibilityHandler;
}

const DEFAULT_WIDTH = '100%';

interface IDynamicNotification extends IDynamicNotificationProps {
    top?: number;
    left?: number;
    right?: number;
}

const transitionStyles = getTransitionStyles();

export const DynamicNotification: FC<IDynamicNotification> = ({
    children,
    kind = 'info',
    width = DEFAULT_WIDTH,
    duration = DEFAULT_DURATION,
    delay,
    container,
    header,
    top,
    left,
    right,
    className,
    dataTestId,
    onShow,
    onHide,
    ...props
}) => {
    const KindIcon = NOTIFICATION_ICONS[kind];
    const mobile = useMobile();
    const [gesture, gestureBinding] = useGesture();
    const [visible, setVisible] = useState(true);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        (gesture === 'left' || gesture === 'right') && setVisible(false);
    }, [gesture, setVisible]);

    const applyTimer = () => {
        if (delay) {
            const timer = setTimeout(() => {
                setVisible(false);
                clearTimeout(timer);
            }, delay);
        }
    };

    const component = (
        <Transition
            in={isMounted && visible}
            timeout={duration}
            onEntered={() => applyTimer()}
            onEnter={() => onShow && onShow()}
            onExited={() => onHide && onHide()}
        >
            {(state) => (
                <DynamicNotificationComponent
                    {...gestureBinding}
                    {...props}
                    mobile={mobile}
                    style={{
                        transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
                        ...transitionStyles[state],
                    }}
                    className={className}
                    data-test-id={dataTestId}
                    kind={kind}
                    width={width}
                    top={top}
                    left={left}
                    right={right}
                >
                    <IconWrapper>
                        <KindIcon width={18} height={18} />
                    </IconWrapper>
                    <IconClose onClick={() => setVisible(false)}>
                        <Times weight="light" width={18} height={18} />
                    </IconClose>
                    {children}
                </DynamicNotificationComponent>
            )}
        </Transition>
    );

    return container ? ReactDOM.createPortal(component, container) : component;
};
