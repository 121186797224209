import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../../../common';
import { getInputHeight } from '../../BaseField/utils-style';
import type { Size, Status } from '../../common';
import { INPUT_BORDER_RADIUS } from '../../constants';

interface IInputBox {
    disabled?: boolean;
    focused: boolean;
    size: Size;
    status: Status;
}

export const InputBox = styled.div<IInputBox>`
    ${({ disabled, theme, focused, size, status }) => css`
        height: ${getInputHeight(size)}px;
        display: flex;
        background-color: ${disabled ? theme.color.neutral[10] : theme.color.neutral[0]};
        border-radius: ${INPUT_BORDER_RADIUS}px;
        ::before {
            content: '';
            position: absolute;
            background: transparent;
            border: 1px solid ${theme.color.neutral[20]};
            border-radius: ${INPUT_BORDER_RADIUS}px;
            width: 100%;
            height: ${getInputHeight(size)}px;
        }
        &:hover::before {
            border-color: ${!focused && status === 'default' && !disabled
                ? theme.color.neutral[50]
                : 'none'};
        }
        &:focus::before {
            outline: none;
            background-color: ${theme.color.neutral[0]};
        }
    `}
`;

InputBox.defaultProps = {
    theme: DEFAULT_THEME,
};
