export type LocaleType = 'ru' | 'enUS' | 'de';

export type Corners = {
    [key in 'top-left' | 'bottom-left' | 'top-right' | 'bottom-right']?: boolean;
};

export type CalendarSize = 'xl' | 'xs';

export const SIZES = {
    xl: 320,
    xs: 248,
};

export const NAVIGATION_PANEL_SIZES = {
    xl: 64,
    xs: 52,
};

export const DAY_SIZES = {
    xl: 40,
    xs: 32,
};

export const DAY_PADDINGS = {
    xl: 12,
    xs: 8,
};

export const NAVIGATION_YEAR_SIZES = {
    xl: 8,
    xs: 6,
};

export const YEAR_SIZES = {
    xl: 68,
    xs: 56,
};

export const YEAR_PADDINGS = {
    xl: 26,
    xs: 20,
};

export const BORDER_RADIUS = 3;

export const DAY_VIEW_PADDINGS = {
    xl: '28px 20px 16px',
    xs: '20px 12px 12px',
};

export const YEAR_VIEW_PADDINGS = {
    xl: '28px 24px 16px',
    xs: '20px 12px 12px',
};

export const HEADER_PADDINGS = {
    xl: '26px 0px 28px 32px',
    xs: '20px 0px 20px 20px',
};

export const PANEL_DAY_VIEW_PADDINGS = {
    xl: '0 4px 28px 10px',
    xs: '0 4px 20px 8px',
};

export const PANEL_YEAR_VIEW_PADDINGS = {
    xl: '0 0 28px 6px',
    xs: '0 4px 20px 8px',
};

export const DAY_NAMES_BOTTOM_MARGINS = {
    xl: 12,
    xs: 8,
};

export const DAY_NUMBERS = [0, 1, 2, 3, 4, 5, 6];

export const MENU_DAY = 'Выбор дня';
export const MENU_YEAR = 'Выбор года';

export const MENU_NEXT_YEAR = 'Вперед';
export const MENU_PREVIOUS_YEAR = 'Назад';

export const MENU_NEXT_MONTH = 'Следующий месяц';
export const MENU_PREVIOUS_MONTH = 'Предыдущий месяц';

export const DEFAULT_SIZE = 'xl';
export const DEFAULT_YEAR_COUNT = 16;
export const DEFAULT_LOCALE_NAME = 'ru';
