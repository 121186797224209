import styled from 'styled-components';
import { transparentize } from 'polished';

import { DEFAULT_THEME, Z_INDEXES } from '../../../common';
import type { ITheme } from '../../../themes';

export interface IOverlayComponentProps {
    inverse?: boolean;
    transparent?: boolean;
}

const getBackgroundColor = ({
    inverse,
    theme,
    transparent,
}: {
    inverse?: boolean;
    theme: ITheme;
    transparent?: boolean;
}) => (transparent ? 'transparent' : transparentize(0.5, theme.color.neutral[inverse ? 80 : 20]));

export const OverlayComponent = styled.div<IOverlayComponentProps>`
    align-items: center;
    background-color: ${({ inverse, theme, transparent }) =>
        getBackgroundColor({ inverse, theme, transparent })};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: ${Z_INDEXES.OVERLAY};
`;

OverlayComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
