export type SelectType = 'default';
export type Size = 'xl' | 'xs';

export const VALUE_CONTAINER_MARGIN_RIGHT = '5px';
export const LABEL_MARGIN_BOTTOM = '8px';
export const ADDITIONAL_TEXT_MARGIN_TOP = '8px';
export const BOTTOM_LINE_HEIGHT_ON_FOCUS = '2px';
export const SELECT_DEFAULT_WIDTH = '320px';
export const CHIP_DEFAULT_MAX_WIDTH = '60px';
export const CLOSE_ICON_MARGIN_RIGHT = '4px';
export const ICON_XL_SIZE = 20;
export const ICON_XS_SIZE = 16;

export const CONTROL_HEIGHT = {
    xs: '30px',
    xl: '40px',
};
export const OPTION_HEIGHT = {
    xs: '30px',
    xl: '40px',
};

export const OPTION_PADDING = {
    xs: '6px 12px',
    xl: '8px 16px',
};
export const CONTROL_PADDING = (type = 'default') => ({
    xs: '6px 11px',
    xl: '8px 15px',
});

export const MAX_LINES_AMOUNT_IN_MENU = 8;
export const MENU_VERTICAL_PADDING = '8px';
export const MENU_MAX_HEIGHT = {
    xs: `${
        parseInt(OPTION_HEIGHT.xs) * MAX_LINES_AMOUNT_IN_MENU + parseInt(MENU_VERTICAL_PADDING) * 2
    }px`,
    xl: `${
        parseInt(OPTION_HEIGHT.xl) * MAX_LINES_AMOUNT_IN_MENU + parseInt(MENU_VERTICAL_PADDING) * 2
    }px`,
};

export const MARK_HEIGHT_SMALL = '16px';
export const MARK_HEIGHT_BIG = '20px';
export const MARK_WIDTH_SMALL = '16px';
export const MARK_WIDTH_BIG = '20px';
export const CHECKBOX_MARGIN = '2px';
export const MARK_BEFORE_OFFSET = '-8px';

export const BORDER_RADIUS = '3px';
export const DROPDOWN_MARGIN_BOTTOM_TOP = '8px';
export const INPUT_BORDER_ACTIVE = '2px';
export const INPUT_BORDER_ERROR = '1px';
