import React from 'react';

import { ReactComponent as EyeLight } from '../../svg/light/eye.svg';
import { ReactComponent as EyeRegular } from '../../svg/regular/eye.svg';
import { ReactComponent as EyeSolid } from '../../svg/solid/eye.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: EyeLight,
    regular: EyeRegular,
    solid: EyeSolid,
};

export const Eye: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
