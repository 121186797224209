import { ICON_SIZE_XL, ICON_SIZE_XS, INPUT_HEIGHT_XL, INPUT_HEIGHT_XS } from '../../constants';
import type { Size } from '../../common';

export const BORDER_RADIUS = 3;

const BASIC_SVG_FLAG_WIDTH = 28;

export const FLAG_WIDTH_XL = 22;
export const FLAG_WIDTH_XS = 18;
export const FLAG_HEIGHT_XL = 16;
export const FLAG_HEIGHT_XS = 13;

const FLAG_SIZE_XL = 22;
const FLAG_SIZE_XS = 18;

export const FLAG_WIDTH_SCALE_XL = FLAG_WIDTH_XL / BASIC_SVG_FLAG_WIDTH;
export const FLAG_WIDTH_SCALE_XS = FLAG_WIDTH_XS / BASIC_SVG_FLAG_WIDTH;

export const DROPDOWN_OFFSET = {
    xs: FLAG_SIZE_XL + (INPUT_HEIGHT_XS - FLAG_SIZE_XL) / 2,
    xl: FLAG_SIZE_XL + (INPUT_HEIGHT_XL - FLAG_SIZE_XL) / 2,
};

export const FLAG_ITEM_PADDING_LEFT = {
    xs: 14,
    xl: 14,
};
export const SELECT_CONTAINER_PADDING_LEFT = FLAG_ITEM_PADDING_LEFT;

const CONTAINER_ICONS_MARGIN = 10;

export const CONTAINER_WIDTH = {
    xs: CONTAINER_ICONS_MARGIN + FLAG_SIZE_XS + ICON_SIZE_XS,
    xl: CONTAINER_ICONS_MARGIN + FLAG_SIZE_XL + ICON_SIZE_XL,
    big: CONTAINER_ICONS_MARGIN + FLAG_SIZE_XL + ICON_SIZE_XL,
};

export const CONTAINER_HEIGHT = {
    xs: INPUT_HEIGHT_XS,
    xl: INPUT_HEIGHT_XL,
};

export const OPTION_HEIGHT = {
    xs: 40,
    xl: 48,
};

export const getInputNumberMargin = (size: Size) => {
    return SELECT_CONTAINER_PADDING_LEFT[size] + CONTAINER_WIDTH[size] + 2;
};
