import styled from 'styled-components';

import { DEFAULT_THEME } from '../../common';

export interface IInputProps {
    disabled?: boolean;
}

export const Input = styled.input<IInputProps>`
    display: none;
`;

Input.defaultProps = {
    theme: DEFAULT_THEME,
};
