import styled from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { MARGIN_BOTTOM } from './constants';

export const Info = styled.div<{ error: boolean }>`
    color: ${({ error, theme }) => (error ? theme.color.danger[60] : theme.color.neutral[90])};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${MARGIN_BOTTOM}px;
`;

Info.defaultProps = {
    theme: DEFAULT_THEME,
};
