import styled, { css } from 'styled-components';

import type { Status } from '../../common';
import { DEFAULT_THEME } from '../../../../common';
import { getAdditionalTextColor } from '../utils-style';
import { TEXT_MARGIN } from '../../constants';
export interface IStyledAdditionalTextProps {
    status: Status;
    disabled?: boolean;
    focused?: boolean;
}

export const StyledAdditionalText = styled.div<IStyledAdditionalTextProps>`
    ${({ status, disabled, theme, focused }) => css`
        color: ${getAdditionalTextColor(status, theme, disabled, focused)};
        margin-top: ${TEXT_MARGIN}px;
        word-break: break-all;
    `}
`;

StyledAdditionalText.defaultProps = {
    theme: DEFAULT_THEME,
};
