export const CHECKBOX_MIN_HEIGHT = 20;
export const BOX_HEIGHT = 20;
export const BOX_WIDTH = 20;
export const MARK_HEIGHT = 12;
export const MARK_WIDTH = 12;
export const INDETERMINATE_MARK_HEIGHT = 3;
export const CHECKBOX_MARGIN = 2;
export const CHECKBOX_MARGIN_WITH_LABEL = 10;
export const BORDER_RADIUS = 3;
export const MARK_BORDER_RADIUS = 2;
export const OUTLINE_SIZE = 2;
export const OUTLINE_BORDER_RADIUS = 5;
export const OUTLINE_OFFSET = -4;
