import styled, { css } from 'styled-components';

import { DEFAULT_THEME, Z_INDEXES } from '../../../../common';
import { TYPOGRAPHY } from '../../../../Typography';
import { INPUT_BORDER_RADIUS, INPUT_PADDING, INPUT_WIDTH } from '../../constants';
import { getInputNumberMargin } from '../../PhoneCountry/StyledComponents/constants';
import type { Size } from '../../common';

export interface IInputComponentProps {
    $size: Size;
    focused: boolean;
    disabled?: boolean;
    withSelectComponent?: boolean;
}

export const InputComponent = styled.input<IInputComponentProps>`
    ${({ theme, disabled, focused, $size, withSelectComponent }) => css`
        width: ${INPUT_WIDTH};
        outline: none;
        height: 100%;
        z-index: ${Z_INDEXES.INPUT};
        padding-left: ${INPUT_PADDING}px;
        ${withSelectComponent ? `margin-left: ${getInputNumberMargin($size)}px;` : ''}
        color: ${disabled ? theme.color.neutral[30] : theme.color.neutral[90]};
        font-family: ${TYPOGRAPHY.fontFamily};
        font-size: ${$size === 'xs' ? TYPOGRAPHY.fontSize[12] : TYPOGRAPHY.fontSize[14]};
        line-height: ${$size === 'xs' ? TYPOGRAPHY.lineHeight[12] : TYPOGRAPHY.lineHeight[20]};
        font-weight: ${TYPOGRAPHY.fontWeight.regular};
        text-overflow: ellipsis;
        border: 1px solid ${focused ? theme.color.primary[50] : theme.color.neutral[20]};
        border-radius: ${INPUT_BORDER_RADIUS}px;
        background: transparent;
        ::-ms-clear {
            display: none;
        }
        ::-ms-reveal {
            display: none;
        }
        ::placeholder,
        ::-webkit-input-placeholder {
            color: ${disabled || focused ? theme.color.neutral[30] : theme.color.neutral[50]};
        }

        ::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    `}
`;

InputComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
