import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import type { Kind } from './Button';
import { BORDER_RADIUS, BORDER_WIDTH, SIZE_XL, SIZE_XS } from './constants';

export type Size = 'xl' | 'xs';

export const SIZE = {
    xl: SIZE_XL,
    xs: SIZE_XS,
};

interface IButtonGroupComponentProps {
    kind: Kind;
    size: Size;
}

export const ButtonGroupComponent = styled.div<IButtonGroupComponentProps>`
    ${({ kind, size, theme }) => css`
        background: ${kind === 'solid' ? theme.color.neutral[0] : 'transparent'};
        border-radius: ${BORDER_RADIUS}px;
        border: ${BORDER_WIDTH}px solid ${theme.color.neutral[10]};
        display: flex;
        height: ${SIZE[size]}px;
    `};
`;

ButtonGroupComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
