import styled, { css } from 'styled-components';

import { Text2 } from '../../../../Typography';
import { DEFAULT_THEME } from '../../../../common';

export const FileErrorText = styled(Text2)`
    ${({ theme }) => css`
        color: ${theme.color.danger[60]};
        margin-top: 8px;
    `}
`;

FileErrorText.defaultProps = {
    theme: DEFAULT_THEME,
};
