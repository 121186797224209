import styled from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { ICON_CLOSE_RIGHT, ICON_CLOSE_TOP } from './constants';

export const IconClose = styled.div<{ markerOffset: number }>`
    position: absolute;
    right: ${ICON_CLOSE_RIGHT}px;
    top: ${ICON_CLOSE_TOP}px;
    display: flex;
    align-items: center;
    cursor: pointer;
    fill: ${({ theme }) => theme.color.neutral[40]};

    &:hover {
        fill: ${({ theme }) => theme.color.neutral[60]};
    }

    &:active {
        fill: ${({ theme }) => theme.color.neutral[80]};
    }
`;

IconClose.defaultProps = {
    theme: DEFAULT_THEME,
};
