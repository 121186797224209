import { TYPOGRAPHY } from '../../Typography';

export const BORDER_TYPE = 'solid';
export const ICON_SIZE_XS = 20;
export const ICON_SIZE_XL = 24;
export const TEXT_MARGIN = 4;
export const ICON_PADDING_RIGHT = 4;
export const INPUT_WIDTH = '100%';
export const INPUT_PADDING = 16;
export const INPUT_BORDER_RADIUS = 4;
export const ICON_WRAPPER_PADDING_RIGHT = 15;
export const DEFAULT_WIDTH = '100%';
export const INPUT_HEIGHT_XL = 42;
export const INPUT_HEIGHT_XS = 30;
export const USER_ICON_PADDING_TOP = 5;
export const SUFFIX_PADDING_XL = 19;
export const SUFFIX_PADDING_XS = 8;
export const FONT_SIZE_BIG = `${TYPOGRAPHY.fontSize[15]} ${TYPOGRAPHY.fontFamily}`;
export const FONT_SIZE_SMALL = `${TYPOGRAPHY.fontSize[13]} ${TYPOGRAPHY.fontFamily}`;
export const COINS_PADDING_XL = 17;
export const COINS_PADDING_XS = 6;
export const COINS_WIDTH_DEFAULT = 22;
export const COINS_WIDTH_MICRO = 20;
export const INCREMENT = 'increment';
export const DECREMENT = 'decrement';
export const DEFAULT_MAX_INPUT_LENGTH = 16;
export const BORDER_BOTTOM_HEIGHT = 2;
export const ICON_PADDING_INPUT_NUMBER_XS = 42;
export const ICON_PADDING_INPUT_NUMBER_DEFAULT = 52;
export const ICON_PADDING = 3;
