import styled, { css } from 'styled-components';

import { TYPOGRAPHY } from './typography';
import type { ITypography } from './typography';

export interface ITextProps {
    color?: string;
}

export const getTextStyle = ({
    color,
    fontSize,
    fontWeight,
    lineHeight,
}: {
    color?: string;
    fontSize: keyof ITypography['fontSize'];
    fontWeight: keyof ITypography['fontWeight'];
    lineHeight: keyof ITypography['lineHeight'];
}) => css`
    color: ${color || 'inherit'};
    font-family: ${TYPOGRAPHY.fontFamily};
    font-size: ${TYPOGRAPHY.fontSize[fontSize]};
    font-weight: ${TYPOGRAPHY.fontWeight[fontWeight]};
    line-height: ${TYPOGRAPHY.lineHeight[lineHeight]};
`;

export const H1 = styled.h1<ITextProps>`
    ${({ color }) =>
        getTextStyle({
            color: color,
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: 30,
        })};
`;

export const H2 = styled.h2<ITextProps>`
    ${({ color }) =>
        getTextStyle({
            color: color,
            fontSize: 18,
            fontWeight: 'bold',
            lineHeight: 20,
        })};
`;

export const H3 = styled.h3<ITextProps>`
    ${({ color }) =>
        getTextStyle({
            color: color,
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 16,
        })};
`;

export const H4 = styled.h4<ITextProps>`
    ${({ color, theme }) =>
        getTextStyle({
            color: color,
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: 14,
        })};
`;

export const Text1 = styled.div<ITextProps>`
    ${({ color, theme }) =>
        getTextStyle({
            color: color,
            fontSize: 16,
            fontWeight: 'regular',
            lineHeight: 24,
        })};
`;

export const Text2 = styled.div<ITextProps>`
    ${({ color, theme }) =>
        getTextStyle({
            color: color,
            fontSize: 14,
            fontWeight: 'regular',
            lineHeight: 15,
        })};
`;

export const Text3 = styled.div<ITextProps>`
    ${({ color, theme }) =>
        getTextStyle({
            color: color,
            fontSize: 12,
            fontWeight: 'regular',
            lineHeight: 15,
        })};
`;

export const ButtonText = styled.div<ITextProps>`
    ${({ color, theme }) =>
        getTextStyle({
            color: color,
            fontSize: 11,
            fontWeight: 'regular',
            lineHeight: 11,
        })};
    height: 9px; // Little hack to display button text one px bottom
`;

export const CheckboxText = styled.div<ITextProps>`
    ${({ color, theme }) =>
        getTextStyle({
            color: color,
            fontSize: 13,
            fontWeight: 'regular',
            lineHeight: 21,
        })};
`;
