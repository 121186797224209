import React, { FC } from 'react';

import { Range, IRangeProps } from '../../Range';
import { IInputDefaultProps } from '../common';

export interface IInputRangeProps {
    /** Добавление суффикса в поле инпута */
    suffix?: string;
    /** Дефолтное значение  */
    defaultValue?: any;
}

export const InputRange: FC<IRangeProps & IInputDefaultProps & IInputRangeProps> = ({
    ...props
}) => {
    return <Range inInput {...props} />;
};
