import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { TabsSize, TAB_HEIGHT_SMALL, TAB_HEIGHT_BIG } from './constants';

interface ITabProps {
    active?: boolean;
    disabled?: boolean;
    size: TabsSize;
}

export const Tab = styled.div<ITabProps>`
    display: flex;
    align-items: center;
    transition: all 0.2s;
    user-select: none;
    &:focus,
    & > div:focus {
        outline: none;
    }

    color: ${({ active, disabled, theme }) =>
        theme.color[active ? 'primary' : 'neutral'][disabled ? 30 : 50]};

    ${({ disabled, theme, size, active }) => css`
        height: ${size === 'xs' ? TAB_HEIGHT_SMALL : TAB_HEIGHT_BIG};
        cursor: ${disabled ? 'default' : 'pointer'};
        & svg {
            fill: ${disabled
                ? theme.color.neutral[30]
                : active
                ? theme.color.primary[50]
                : theme.color.neutral[50]};
        }
        &:focus {
            background-color: red;
        }
    `}

    ${({ theme, disabled, active }) =>
        !disabled &&
        css`
            &:hover {
                background-color: ${active ? theme.color.primary[10] : theme.color.neutral[10]};
            }
            &:active {
                background-color: ${active ? theme.color.primary[70] : theme.color.neutral[20]};
            }
        `}
`;

Tab.defaultProps = {
    theme: DEFAULT_THEME,
};
