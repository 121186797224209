import React from 'react';
import { components } from 'react-select';
import { EllipsisH } from '@legex/icons';

import { IconWrapper } from './simple/IconWrapper';
import { IndicatorsWrapper } from './simple/IndicatorsWrapper';

export const DropdownIndicator = (props: any) => {
    const {
        getStyles,
        innerProps: { ref, ...restInnerProps },
        selectProps: { isDisabled, size },
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={{
                ...getStyles('clearIndicator', props),
                padding: 0,
            }}
        >
            <IconWrapper disabled={isDisabled}>
                <EllipsisH width={size} height={size} />
            </IconWrapper>
        </div>
    );
};

export const IndicatorsContainer = (props: any) => {
    const { size, menuIsOpen, isFocused } = props.selectProps;
    return (
        <IndicatorsWrapper size={size} menuIsOpen={menuIsOpen} focused={isFocused}>
            <components.IndicatorsContainer {...props} />
        </IndicatorsWrapper>
    );
};
