import styled, { css } from 'styled-components';

import { getInputHeight } from '../utils-style';
import { DEFAULT_THEME } from '../../../../common';
import type { Size, Status } from '../../common';
import { INPUT_BORDER_RADIUS } from '../../constants';

export interface IInputWrapperProps {
    size: Size;
    focused: boolean;
    status: Status;
    disabled?: boolean;
    range?: boolean;
}

export const InputWrapper = styled.div<IInputWrapperProps>`
    ${({ theme, disabled, focused, status, size, range }) => css`
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        height: ${getInputHeight(size)}px;
        margin: 0;
        border-radius: ${INPUT_BORDER_RADIUS}px;
        background-color: ${disabled ? theme.color.neutral[10] : theme.color.neutral[0]};
        ::before {
            content: '';
            top: 0;
            position: absolute;
            background: transparent;
            border: 1px solid ${theme.color.neutral[20]};
            border-radius: ${INPUT_BORDER_RADIUS}px;
            width: 100%;
            height: ${getInputHeight(size)}px;
        }
        &:hover::before {
            border-color: ${!focused && status === 'default' && !disabled
                ? theme.color.neutral[50]
                : 'none'};
        }
        &:focus::before {
            outline: none;
            background-color: ${theme.color.neutral[0]};
        }
    `}
`;

InputWrapper.defaultProps = {
    theme: DEFAULT_THEME,
};
