import styled, { css } from 'styled-components';
import { Minus as IconMinus } from '@legex/icons';
import React from 'react';

import { getDefaultIconStyled, IStyledIconDefaultProps } from '../common';
import { DEFAULT_THEME } from '../../../common';

const Minus = (props) => {
    return <IconMinus weight="light" {...props} />;
};

export const StyledMinusOutline = styled(Minus)<IStyledIconDefaultProps>`
    ${({ theme, disabled }) => css`
        ${getDefaultIconStyled(theme, disabled)}
    `}
`;

StyledMinusOutline.defaultProps = {
    theme: DEFAULT_THEME,
};
