import React from 'react';

import { ReactComponent as PencilPaintbrushLight } from '../../svg/light/pencil-paintbrush.svg';
import { ReactComponent as PencilPaintbrushRegular } from '../../svg/regular/pencil-paintbrush.svg';
import { ReactComponent as PencilPaintbrushSolid } from '../../svg/solid/pencil-paintbrush.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: PencilPaintbrushLight,
    regular: PencilPaintbrushRegular,
    solid: PencilPaintbrushSolid,
};

export const PencilPaintbrush: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
