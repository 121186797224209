import type { ITheme } from '../../themes';

import { Kind } from './Button';

export const getBackgroundColor = (kind: Kind, color: ITheme['color']) => {
    switch (kind) {
        case 'neutral':
            return color.neutral[10];
        case 'primary':
            return color.primary[20];
        case 'warning':
            return color.warning[20];
        case 'danger':
            return color.danger[20];
        case 'success':
            return color.success[20];
        default:
            return color.primary[20];
    }
};
