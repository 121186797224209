import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import {
    INDETERMINATE_MARK_HEIGHT,
    MARK_BORDER_RADIUS,
    MARK_HEIGHT,
    MARK_WIDTH,
} from './constants';
import { ICommonProps } from './common';

export const Mark = styled.div<ICommonProps>`
    transition: height 0.2s ease, width 0.2s ease;

    ${({ theme, indeterminate, checked }) => css`
        background-color: ${theme.color[checked ? 'primary' : 'neutral'][50]};
        border-radius: ${MARK_BORDER_RADIUS}px;
        height: ${indeterminate ? INDETERMINATE_MARK_HEIGHT : checked ? MARK_HEIGHT : 0}px;
        width: ${indeterminate || checked ? MARK_WIDTH : 0}px;
    `}
`;

Mark.defaultProps = {
    theme: DEFAULT_THEME,
};
