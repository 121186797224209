import styled from 'styled-components';

export const StyledValueContainer = styled.div`
    -webkit-overflow-scrolling: touch;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
`;
