import React from 'react';

import { ReactComponent as ChevronLeftLight } from '../../svg/light/chevron-left.svg';
import { ReactComponent as ChevronLeftCircleLight } from '../../svg/light/chevron-circle-left.svg';
import { ReactComponent as ChevronLeftSquareLight } from '../../svg/light/chevron-square-left.svg';
import { ReactComponent as ChevronLeftRegular } from '../../svg/regular/chevron-left.svg';
import { ReactComponent as ChevronLeftCircleRegular } from '../../svg/regular/chevron-circle-left.svg';
import { ReactComponent as ChevronLeftSquareRegular } from '../../svg/regular/chevron-square-left.svg';
import { ReactComponent as ChevronLeftSolid } from '../../svg/solid/chevron-left.svg';
import { ReactComponent as ChevronLeftCircleSolid } from '../../svg/solid/chevron-circle-left.svg';
import { ReactComponent as ChevronLeftSquareSolid } from '../../svg/solid/chevron-square-left.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: ChevronLeftLight,
        regular: ChevronLeftRegular,
        solid: ChevronLeftSolid,
    },
    circle: {
        light: ChevronLeftCircleLight,
        regular: ChevronLeftCircleRegular,
        solid: ChevronLeftCircleSolid,
    },
    square: {
        light: ChevronLeftSquareLight,
        regular: ChevronLeftSquareRegular,
        solid: ChevronLeftSquareSolid,
    },
};

export const ChevronLeft: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
