import styled, { css } from 'styled-components';

import {
    NotificationComponent,
    INotificationComponentProps,
} from '../common/NotificationComponent';

interface IStaticNotificationComponentProps extends INotificationComponentProps {
    showWhiteBackground?: boolean;
}

export const StaticNotificationComponent = styled(
    NotificationComponent
)<IStaticNotificationComponentProps>`
    ${({ theme, showWhiteBackground }) => css`
        ${showWhiteBackground ? `background-color: ${theme.color.neutral[0]}` : ''};
    `}
`;
