import React from 'react';

import { ReactComponent as EnvelopeLight } from '../../svg/light/envelope.svg';
import { ReactComponent as EnvelopeRegular } from '../../svg/regular/envelope.svg';
import { ReactComponent as EnvelopeSolid } from '../../svg/solid/envelope.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: EnvelopeLight,
    regular: EnvelopeRegular,
    solid: EnvelopeSolid,
};

export const Envelope: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
