export const SWITCH_HEIGHT_XL = 20;
export const SWITCH_HEIGHT_XS = 16;

export const SWITCH_BORDER_WIDTH = 1;

export const SWITCH_WIDTH_XL = 36;
export const SWITCH_WIDTH_XS = 28;

export const BORDER_RADIUS_XL = 18;
export const BORDER_RADIUS_XS = 12;

export const INNER_CIRCLE_SIZE_XL = 16;
export const INNER_CIRCLE_SIZE_XS = 12;

export const CIRCLE_TRANSLATE_X_XL = 14;
export const CIRCLE_TRANSLATE_X_XS = 10;

export const LABEL_MARGIN = 8;

export const OUTLINE_SIZE = 2;
export const OUTLINE_OFFSET = -4;
export const OUTLINE_BORDER_RADIUS_XL = 18;
export const OUTLINE_BORDER_RADIUS_XS = 12;
