import React from 'react';

import { ReactComponent as EyeSlashLight } from '../../svg/light/eye-slash.svg';
import { ReactComponent as EyeSlashRegular } from '../../svg/regular/eye-slash.svg';
import { ReactComponent as EyeSlashSolid } from '../../svg/solid/eye-slash.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: EyeSlashLight,
    regular: EyeSlashRegular,
    solid: EyeSlashSolid,
};

export const EyeSlash: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
