import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';
import type { ITheme } from '../../themes';
import { CheckboxText } from '../../Typography';

import type { ICommonProps } from './common';

const commonLabelStyles = (theme: ITheme, disabled?: boolean) => css`
    display: flex;
    align-items: center;
    user-select: none;
    width: 100%;
    color: ${disabled ? theme.color.neutral[30] : theme.color.neutral[90]};

    &:focus {
        outline: none;
    }
`;

export const Label = styled(CheckboxText).attrs({ tabIndex: -1 })<ICommonProps>`
    ${({ theme, disabled }) => commonLabelStyles(theme, disabled)}
`;

Label.defaultProps = {
    theme: DEFAULT_THEME,
};
