import styled from 'styled-components';

import { MEDIA_MOBILE } from '../../common/media';
import { getTextStyle } from '../../Typography';

import { HEADER_MARGIN_BOTTOM } from './constants';

export const Header = styled.div`
    white-space: pre-wrap;
    margin-bottom: ${HEADER_MARGIN_BOTTOM};

    ${({ theme }) =>
        getTextStyle({
            color: theme.color.neutral[70],
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: 20,
        })}

    @media ${MEDIA_MOBILE} {
        ${({ theme }) =>
            getTextStyle({
                color: theme.color.neutral[70],
                fontSize: 18,
                fontWeight: 'bold',
                lineHeight: 20,
            })}
    }
`;
