export const CHARS_TO_SHOW_SUGGEST = 1;
export const DEFAULT_NO_OPTION_MESSAGE = () => 'Варианты не найдены';
export const MENU_WRAPPER_Z_INDEX = 5;
export const MENU_PADDING = 8;
export const MAX_OPTIONS_BEFORE_SCROLL = 3;
export const OPTION_HORIZONTAL_PADDING = 16;
export const BORDER_RADIUS = 4;

export const OPTION_SIZE = {
    xl: 48,
    xs: 40,
};

export const OPTION_FONT_SIZE = {
    xl: 15,
    xs: 15,
};
