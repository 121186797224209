import { ReactComponent as Afghanistan } from '../svg/flags/Afghanistan.svg';
import { ReactComponent as AlandIslands } from '../svg/flags/AlandIslands.svg';
import { ReactComponent as Albania } from '../svg/flags/Albania.svg';
import { ReactComponent as Algeria } from '../svg/flags/Algeria.svg';
import { ReactComponent as Andorra } from '../svg/flags/Andorra.svg';
import { ReactComponent as Angola } from '../svg/flags/Angola.svg';
import { ReactComponent as Anguilla } from '../svg/flags/Anguilla.svg';
import { ReactComponent as AntiguaAndBarbuda } from '../svg/flags/AntiguaAndBarbuda.svg';
import { ReactComponent as ArabEmirates } from '../svg/flags/ArabEmirates.svg';
import { ReactComponent as Argentina } from '../svg/flags/Argentina.svg';
import { ReactComponent as Armenia } from '../svg/flags/Armenia.svg';
import { ReactComponent as Aruba } from '../svg/flags/Aruba.svg';
import { ReactComponent as Australia } from '../svg/flags/Australia.svg';
import { ReactComponent as Austria } from '../svg/flags/Austria.svg';
import { ReactComponent as Azerbaijan } from '../svg/flags/Azerbaijan.svg';
import { ReactComponent as Bahamas } from '../svg/flags/Bahamas.svg';
import { ReactComponent as Bahrain } from '../svg/flags/Bahrain.svg';
import { ReactComponent as Bangladesh } from '../svg/flags/Bangladesh.svg';
import { ReactComponent as Barbados } from '../svg/flags/Barbados.svg';
import { ReactComponent as Belarus } from '../svg/flags/Belarus.svg';
import { ReactComponent as Belgium } from '../svg/flags/Belgium.svg';
import { ReactComponent as Belize } from '../svg/flags/Belize.svg';
import { ReactComponent as Benin } from '../svg/flags/Benin.svg';
import { ReactComponent as Bermuda } from '../svg/flags/Bermuda.svg';
import { ReactComponent as Bhutan } from '../svg/flags/Bhutan.svg';
import { ReactComponent as Bolivia } from '../svg/flags/Bolivia.svg';
import { ReactComponent as BosniaAndHerzegovina } from '../svg/flags/BosniaAndHerzegovina.svg';
import { ReactComponent as Botswana } from '../svg/flags/Botswana.svg';
import { ReactComponent as Brazil } from '../svg/flags/Brazil.svg';
import { ReactComponent as BritishVirginIslands } from '../svg/flags/BritishVirginIslands.svg';
import { ReactComponent as Brunei } from '../svg/flags/Brunei.svg';
import { ReactComponent as Bulgaria } from '../svg/flags/Bulgaria.svg';
import { ReactComponent as BurkinaFaso } from '../svg/flags/BurkinaFaso.svg';
import { ReactComponent as Burundi } from '../svg/flags/Burundi.svg';
import { ReactComponent as CaboVerde } from '../svg/flags/CaboVerde.svg';
import { ReactComponent as Cambodia } from '../svg/flags/Cambodia.svg';
import { ReactComponent as Cameroon } from '../svg/flags/Cameroon.svg';
import { ReactComponent as Canada } from '../svg/flags/Canada.svg';
import { ReactComponent as CaymanIslands } from '../svg/flags/CaymanIslands.svg';
import { ReactComponent as CentralAfricanRepublic } from '../svg/flags/CentralAfricanRepublic.svg';
import { ReactComponent as Chad } from '../svg/flags/Chad.svg';
import { ReactComponent as Chile } from '../svg/flags/Chile.svg';
import { ReactComponent as China } from '../svg/flags/China.svg';
import { ReactComponent as Colombia } from '../svg/flags/Colombia.svg';
import { ReactComponent as Comoros } from '../svg/flags/Comoros.svg';
import { ReactComponent as CostaRica } from '../svg/flags/CostaRica.svg';
import { ReactComponent as CotedIvoire } from '../svg/flags/CotedIvoire.svg';
import { ReactComponent as Croatia } from '../svg/flags/Croatia.svg';
import { ReactComponent as Cuba } from '../svg/flags/Cuba.svg';
import { ReactComponent as Cyprus } from '../svg/flags/Cyprus.svg';
import { ReactComponent as Czechia } from '../svg/flags/Czechia.svg';
import { ReactComponent as DemocraticRepublicOfTheCongo } from '../svg/flags/DemocraticRepublicOfTheCongo.svg';
import { ReactComponent as Denmark } from '../svg/flags/Denmark.svg';
import { ReactComponent as Djibouti } from '../svg/flags/Djibouti.svg';
import { ReactComponent as Dominica } from '../svg/flags/Dominica.svg';
import { ReactComponent as DominicanRepublic } from '../svg/flags/DominicanRepublic.svg';
import { ReactComponent as Ecuador } from '../svg/flags/Ecuador.svg';
import { ReactComponent as Egypt } from '../svg/flags/Egypt.svg';
import { ReactComponent as ElSalvador } from '../svg/flags/ElSalvador.svg';
import { ReactComponent as EquatorialGuinea } from '../svg/flags/EquatorialGuinea.svg';
import { ReactComponent as Eritrea } from '../svg/flags/Eritrea.svg';
import { ReactComponent as Estonia } from '../svg/flags/Estonia.svg';
import { ReactComponent as Ethiopia } from '../svg/flags/Ethiopia.svg';
import { ReactComponent as FalklandIslands } from '../svg/flags/FalklandIslands.svg';
import { ReactComponent as Fiji } from '../svg/flags/Fiji.svg';
import { ReactComponent as Finland } from '../svg/flags/Finland.svg';
import { ReactComponent as France } from '../svg/flags/France.svg';
import { ReactComponent as Gabon } from '../svg/flags/Gabon.svg';
import { ReactComponent as Gambia } from '../svg/flags/Gambia.svg';
import { ReactComponent as Georgia } from '../svg/flags/Georgia.svg';
import { ReactComponent as Germany } from '../svg/flags/Germany.svg';
import { ReactComponent as Ghana } from '../svg/flags/Ghana.svg';
import { ReactComponent as Gibraltar } from '../svg/flags/Gibraltar.svg';
import { ReactComponent as GreatBritain } from '../svg/flags/GreatBritain.svg';
import { ReactComponent as Greece } from '../svg/flags/Greece.svg';
import { ReactComponent as Grenada } from '../svg/flags/Grenada.svg';
import { ReactComponent as Guatemala } from '../svg/flags/Guatemala.svg';
import { ReactComponent as Guernsey } from '../svg/flags/Guernsey.svg';
import { ReactComponent as Guinea } from '../svg/flags/Guinea.svg';
import { ReactComponent as GuineaBissau } from '../svg/flags/GuineaBissau.svg';
import { ReactComponent as Guyana } from '../svg/flags/Guyana.svg';
import { ReactComponent as Haiti } from '../svg/flags/Haiti.svg';
import { ReactComponent as Honduras } from '../svg/flags/Honduras.svg';
import { ReactComponent as HongKong } from '../svg/flags/HongKong.svg';
import { ReactComponent as Hungary } from '../svg/flags/Hungary.svg';
import { ReactComponent as Iceland } from '../svg/flags/Iceland.svg';
import { ReactComponent as India } from '../svg/flags/India.svg';
import { ReactComponent as Indonesia } from '../svg/flags/Indonesia.svg';
import { ReactComponent as Iran } from '../svg/flags/Iran.svg';
import { ReactComponent as Iraq } from '../svg/flags/Iraq.svg';
import { ReactComponent as Ireland } from '../svg/flags/Ireland.svg';
import { ReactComponent as IsleOfMan } from '../svg/flags/IsleOfMan.svg';
import { ReactComponent as Israel } from '../svg/flags/Israel.svg';
import { ReactComponent as Italy } from '../svg/flags/Italy.svg';
import { ReactComponent as Jamaica } from '../svg/flags/Jamaica.svg';
import { ReactComponent as Japan } from '../svg/flags/Japan.svg';
import { ReactComponent as Jersey } from '../svg/flags/Jersey.svg';
import { ReactComponent as Jordan } from '../svg/flags/Jordan.svg';
import { ReactComponent as Kazakhstan } from '../svg/flags/Kazakhstan.svg';
import { ReactComponent as Kenya } from '../svg/flags/Kenya.svg';
import { ReactComponent as KoreaNorth } from '../svg/flags/KoreaNorth.svg';
import { ReactComponent as KoreaSouth } from '../svg/flags/KoreaSouth.svg';
import { ReactComponent as Kuwait } from '../svg/flags/Kuwait.svg';
import { ReactComponent as Kyrgyzstan } from '../svg/flags/Kyrgyzstan.svg';
import { ReactComponent as Laos } from '../svg/flags/Laos.svg';
import { ReactComponent as Latvia } from '../svg/flags/Latvia.svg';
import { ReactComponent as Lebanon } from '../svg/flags/Lebanon.svg';
import { ReactComponent as Lesotho } from '../svg/flags/Lesotho.svg';
import { ReactComponent as Liberia } from '../svg/flags/Liberia.svg';
import { ReactComponent as Libya } from '../svg/flags/Libya.svg';
import { ReactComponent as Liechtenstein } from '../svg/flags/Liechtenstein.svg';
import { ReactComponent as Lithuania } from '../svg/flags/Lithuania.svg';
import { ReactComponent as Luxembourg } from '../svg/flags/Luxembourg.svg';
import { ReactComponent as Macao } from '../svg/flags/Macao.svg';
import { ReactComponent as Macedonia } from '../svg/flags/Macedonia.svg';
import { ReactComponent as Madagascar } from '../svg/flags/Madagascar.svg';
import { ReactComponent as Malawi } from '../svg/flags/Malawi.svg';
import { ReactComponent as Malaysia } from '../svg/flags/Malaysia.svg';
import { ReactComponent as Maldives } from '../svg/flags/Maldives.svg';
import { ReactComponent as Mali } from '../svg/flags/Mali.svg';
import { ReactComponent as Malta } from '../svg/flags/Malta.svg';
import { ReactComponent as Mauritania } from '../svg/flags/Mauritania.svg';
import { ReactComponent as Mauritius } from '../svg/flags/Mauritius.svg';
import { ReactComponent as Mexico } from '../svg/flags/Mexico.svg';
import { ReactComponent as Micronesia } from '../svg/flags/Micronesia.svg';
import { ReactComponent as Moldova } from '../svg/flags/Moldova.svg';
import { ReactComponent as Monaco } from '../svg/flags/Monaco.svg';
import { ReactComponent as Mongolia } from '../svg/flags/Mongolia.svg';
import { ReactComponent as Montenegro } from '../svg/flags/Montenegro.svg';
import { ReactComponent as Montserrat } from '../svg/flags/Montserrat.svg';
import { ReactComponent as Morocco } from '../svg/flags/Morocco.svg';
import { ReactComponent as Mozambique } from '../svg/flags/Mozambique.svg';
import { ReactComponent as Myanmar } from '../svg/flags/Myanmar.svg';
import { ReactComponent as Namibia } from '../svg/flags/Namibia.svg';
import { ReactComponent as Nepal } from '../svg/flags/Nepal.svg';
import { ReactComponent as Netherlands } from '../svg/flags/Netherlands.svg';
import { ReactComponent as NetherlandsAntillesCountry } from '../svg/flags/NetherlandsAntillesCountry.svg';
import { ReactComponent as NewZealand } from '../svg/flags/NewZealand.svg';
import { ReactComponent as Nicaragua } from '../svg/flags/Nicaragua.svg';
import { ReactComponent as Niger } from '../svg/flags/Niger.svg';
import { ReactComponent as Nigeria } from '../svg/flags/Nigeria.svg';
import { ReactComponent as Norway } from '../svg/flags/Norway.svg';
import { ReactComponent as Oman } from '../svg/flags/Oman.svg';
import { ReactComponent as Pakistan } from '../svg/flags/Pakistan.svg';
import { ReactComponent as Palau } from '../svg/flags/Palau.svg';
import { ReactComponent as Panama } from '../svg/flags/Panama.svg';
import { ReactComponent as PapuaNewGuinea } from '../svg/flags/PapuaNewGuinea.svg';
import { ReactComponent as Paraguay } from '../svg/flags/Paraguay.svg';
import { ReactComponent as Peru } from '../svg/flags/Peru.svg';
import { ReactComponent as Philippines } from '../svg/flags/Philippines.svg';
import { ReactComponent as Poland } from '../svg/flags/Poland.svg';
import { ReactComponent as PolynesiaFrench } from '../svg/flags/PolynesiaFrench.svg';
import { ReactComponent as Portugal } from '../svg/flags/Portugal.svg';
import { ReactComponent as PuertoRico } from '../svg/flags/PuertoRico.svg';
import { ReactComponent as Qatar } from '../svg/flags/Qatar.svg';
import { ReactComponent as RepublicOfTheCongo } from '../svg/flags/RepublicOfTheCongo.svg';
import { ReactComponent as Romania } from '../svg/flags/Romania.svg';
import { ReactComponent as Russia } from '../svg/flags/Russia.svg';
import { ReactComponent as Rwanda } from '../svg/flags/Rwanda.svg';
import { ReactComponent as SaintHelena } from '../svg/flags/SaintHelena.svg';
import { ReactComponent as SaintKittsAndNevis } from '../svg/flags/SaintKittsAndNevis.svg';
import { ReactComponent as SaintLucia } from '../svg/flags/SaintLucia.svg';
import { ReactComponent as SaintVincentAndTheGrenadines } from '../svg/flags/SaintVincentAndTheGrenadines.svg';
import { ReactComponent as Samoa } from '../svg/flags/Samoa.svg';
import { ReactComponent as SanMarino } from '../svg/flags/SanMarino.svg';
import { ReactComponent as SaoTomeAndPrincipe } from '../svg/flags/SaoTomeAndPrincipe.svg';
import { ReactComponent as SaudiArabia } from '../svg/flags/SaudiArabia.svg';
import { ReactComponent as Senegal } from '../svg/flags/Senegal.svg';
import { ReactComponent as Serbia } from '../svg/flags/Serbia.svg';
import { ReactComponent as Seychelles } from '../svg/flags/Seychelles.svg';
import { ReactComponent as SierraLeone } from '../svg/flags/SierraLeone.svg';
import { ReactComponent as Singapore } from '../svg/flags/Singapore.svg';
import { ReactComponent as Slovakia } from '../svg/flags/Slovakia.svg';
import { ReactComponent as Slovenia } from '../svg/flags/Slovenia.svg';
import { ReactComponent as SolomonIslands } from '../svg/flags/SolomonIslands.svg';
import { ReactComponent as Somalia } from '../svg/flags/Somalia.svg';
import { ReactComponent as SouthAfrica } from '../svg/flags/SouthAfrica.svg';
import { ReactComponent as Spain } from '../svg/flags/Spain.svg';
import { ReactComponent as SriLanka } from '../svg/flags/SriLanka.svg';
import { ReactComponent as Sudan } from '../svg/flags/Sudan.svg';
import { ReactComponent as Suriname } from '../svg/flags/Suriname.svg';
import { ReactComponent as Swaziland } from '../svg/flags/Swaziland.svg';
import { ReactComponent as Sweden } from '../svg/flags/Sweden.svg';
import { ReactComponent as Switzerland } from '../svg/flags/Switzerland.svg';
import { ReactComponent as Syria } from '../svg/flags/Syria.svg';
import { ReactComponent as Taiwan } from '../svg/flags/Taiwan.svg';
import { ReactComponent as Tajikistan } from '../svg/flags/Tajikistan.svg';
import { ReactComponent as Tanzania } from '../svg/flags/Tanzania.svg';
import { ReactComponent as Thailand } from '../svg/flags/Thailand.svg';
import { ReactComponent as TimorLeste } from '../svg/flags/TimorLeste.svg';
import { ReactComponent as Togo } from '../svg/flags/Togo.svg';
import { ReactComponent as Tonga } from '../svg/flags/Tonga.svg';
import { ReactComponent as TrinidadAndTobago } from '../svg/flags/TrinidadAndTobago.svg';
import { ReactComponent as Tunisia } from '../svg/flags/Tunisia.svg';
import { ReactComponent as Turkey } from '../svg/flags/Turkey.svg';
import { ReactComponent as Turkmenistan } from '../svg/flags/Turkmenistan.svg';
import { ReactComponent as TurksAndCaicosIslands } from '../svg/flags/TurksAndCaicosIslands.svg';
import { ReactComponent as Uganda } from '../svg/flags/Uganda.svg';
import { ReactComponent as Ukraine } from '../svg/flags/Ukraine.svg';
import { ReactComponent as UnitedStatesOfAmerica } from '../svg/flags/UnitedStatesOfAmerica.svg';
import { ReactComponent as Uruguay } from '../svg/flags/Uruguay.svg';
import { ReactComponent as Uzbekistan } from '../svg/flags/Uzbekistan.svg';
import { ReactComponent as Vanuatu } from '../svg/flags/Vanuatu.svg';
import { ReactComponent as Venezuela } from '../svg/flags/Venezuela.svg';
import { ReactComponent as Vietnam } from '../svg/flags/Vietnam.svg';
import { ReactComponent as Yemen } from '../svg/flags/Yemen.svg';
import { ReactComponent as Zambia } from '../svg/flags/Zambia.svg';
import { ReactComponent as Zimbabwe } from '../svg/flags/Zimbabwe.svg';

export const Flag = {
    Afghanistan: Afghanistan,
    AlandIslands: AlandIslands,
    Albania: Albania,
    Algeria: Algeria,
    Andorra: Andorra,
    Angola: Angola,
    Anguilla: Anguilla,
    AntiguaAndBarbuda: AntiguaAndBarbuda,
    ArabEmirates: ArabEmirates,
    Argentina: Argentina,
    Armenia: Armenia,
    Aruba: Aruba,
    Australia: Australia,
    Austria: Austria,
    Azerbaijan: Azerbaijan,
    Bahamas: Bahamas,
    Bahrain: Bahrain,
    Bangladesh: Bangladesh,
    Barbados: Barbados,
    Belarus: Belarus,
    Belgium: Belgium,
    Belize: Belize,
    Benin: Benin,
    Bermuda: Bermuda,
    Bhutan: Bhutan,
    Bolivia: Bolivia,
    BosniaAndHerzegovina: BosniaAndHerzegovina,
    Botswana: Botswana,
    Brazil: Brazil,
    BritishVirginIslands: BritishVirginIslands,
    Brunei: Brunei,
    Bulgaria: Bulgaria,
    BurkinaFaso: BurkinaFaso,
    Burundi: Burundi,
    CaboVerde: CaboVerde,
    Cambodia: Cambodia,
    Cameroon: Cameroon,
    Canada: Canada,
    CaymanIslands: CaymanIslands,
    CentralAfricanRepublic: CentralAfricanRepublic,
    Chad: Chad,
    Chile: Chile,
    China: China,
    Colombia: Colombia,
    Comoros: Comoros,
    CostaRica: CostaRica,
    CotedIvoire: CotedIvoire,
    Croatia: Croatia,
    Cuba: Cuba,
    Cyprus: Cyprus,
    Czechia: Czechia,
    DemocraticRepublicOfTheCongo: DemocraticRepublicOfTheCongo,
    Denmark: Denmark,
    Djibouti: Djibouti,
    Dominica: Dominica,
    DominicanRepublic: DominicanRepublic,
    Ecuador: Ecuador,
    Egypt: Egypt,
    ElSalvador: ElSalvador,
    EquatorialGuinea: EquatorialGuinea,
    Eritrea: Eritrea,
    Estonia: Estonia,
    Ethiopia: Ethiopia,
    FalklandIslands: FalklandIslands,
    Fiji: Fiji,
    Finland: Finland,
    France: France,
    Gabon: Gabon,
    Gambia: Gambia,
    Georgia: Georgia,
    Germany: Germany,
    Ghana: Ghana,
    Gibraltar: Gibraltar,
    GreatBritain: GreatBritain,
    Greece: Greece,
    Grenada: Grenada,
    Guatemala: Guatemala,
    Guernsey: Guernsey,
    Guinea: Guinea,
    GuineaBissau: GuineaBissau,
    Guyana: Guyana,
    Haiti: Haiti,
    Honduras: Honduras,
    HongKong: HongKong,
    Hungary: Hungary,
    Iceland: Iceland,
    India: India,
    Indonesia: Indonesia,
    Iran: Iran,
    Iraq: Iraq,
    Ireland: Ireland,
    IsleOfMan: IsleOfMan,
    Israel: Israel,
    Italy: Italy,
    Jamaica: Jamaica,
    Japan: Japan,
    Jersey: Jersey,
    Jordan: Jordan,
    Kazakhstan: Kazakhstan,
    Kenya: Kenya,
    KoreaNorth: KoreaNorth,
    KoreaSouth: KoreaSouth,
    Kuwait: Kuwait,
    Kyrgyzstan: Kyrgyzstan,
    Laos: Laos,
    Latvia: Latvia,
    Lebanon: Lebanon,
    Lesotho: Lesotho,
    Liberia: Liberia,
    Libya: Libya,
    Liechtenstein: Liechtenstein,
    Lithuania: Lithuania,
    Luxembourg: Luxembourg,
    Macao: Macao,
    Macedonia: Macedonia,
    Madagascar: Madagascar,
    Malawi: Malawi,
    Malaysia: Malaysia,
    Maldives: Maldives,
    Mali: Mali,
    Malta: Malta,
    Mauritania: Mauritania,
    Mauritius: Mauritius,
    Mexico: Mexico,
    Micronesia: Micronesia,
    Moldova: Moldova,
    Monaco: Monaco,
    Mongolia: Mongolia,
    Montenegro: Montenegro,
    Montserrat: Montserrat,
    Morocco: Morocco,
    Mozambique: Mozambique,
    Myanmar: Myanmar,
    Namibia: Namibia,
    Nepal: Nepal,
    Netherlands: Netherlands,
    NetherlandsAntillesCountry: NetherlandsAntillesCountry,
    NewZealand: NewZealand,
    Nicaragua: Nicaragua,
    Niger: Niger,
    Nigeria: Nigeria,
    Norway: Norway,
    Oman: Oman,
    Pakistan: Pakistan,
    Palau: Palau,
    Panama: Panama,
    PapuaNewGuinea: PapuaNewGuinea,
    Paraguay: Paraguay,
    Peru: Peru,
    Philippines: Philippines,
    Poland: Poland,
    PolynesiaFrench: PolynesiaFrench,
    Portugal: Portugal,
    PuertoRico: PuertoRico,
    Qatar: Qatar,
    RepublicOfTheCongo: RepublicOfTheCongo,
    Romania: Romania,
    Russia: Russia,
    Rwanda: Rwanda,
    SaintHelena: SaintHelena,
    SaintKittsAndNevis: SaintKittsAndNevis,
    SaintLucia: SaintLucia,
    SaintVincentAndTheGrenadines: SaintVincentAndTheGrenadines,
    Samoa: Samoa,
    SanMarino: SanMarino,
    SaoTomeAndPrincipe: SaoTomeAndPrincipe,
    SaudiArabia: SaudiArabia,
    Senegal: Senegal,
    Serbia: Serbia,
    Seychelles: Seychelles,
    SierraLeone: SierraLeone,
    Singapore: Singapore,
    Slovakia: Slovakia,
    Slovenia: Slovenia,
    SolomonIslands: SolomonIslands,
    Somalia: Somalia,
    SouthAfrica: SouthAfrica,
    Spain: Spain,
    SriLanka: SriLanka,
    Sudan: Sudan,
    Suriname: Suriname,
    Swaziland: Swaziland,
    Sweden: Sweden,
    Switzerland: Switzerland,
    Syria: Syria,
    Taiwan: Taiwan,
    Tajikistan: Tajikistan,
    Tanzania: Tanzania,
    Thailand: Thailand,
    TimorLeste: TimorLeste,
    Togo: Togo,
    Tonga: Tonga,
    TrinidadAndTobago: TrinidadAndTobago,
    Tunisia: Tunisia,
    Turkey: Turkey,
    Turkmenistan: Turkmenistan,
    TurksAndCaicosIslands: TurksAndCaicosIslands,
    Uganda: Uganda,
    Ukraine: Ukraine,
    UnitedStatesOfAmerica: UnitedStatesOfAmerica,
    Uruguay: Uruguay,
    Uzbekistan: Uzbekistan,
    Vanuatu: Vanuatu,
    Venezuela: Venezuela,
    Vietnam: Vietnam,
    Yemen: Yemen,
    Zambia: Zambia,
    Zimbabwe: Zimbabwe,
};
