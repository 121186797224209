import React from 'react';

import { ReactComponent as InfoLight } from '../../svg/light/info.svg';
import { ReactComponent as InfoCircleLight } from '../../svg/light/info-circle.svg';
import { ReactComponent as InfoSquareLight } from '../../svg/light/info-square.svg';
import { ReactComponent as InfoRegular } from '../../svg/regular/info.svg';
import { ReactComponent as InfoCircleRegular } from '../../svg/regular/info-circle.svg';
import { ReactComponent as InfoSquareRegular } from '../../svg/regular/info-square.svg';
import { ReactComponent as InfoSolid } from '../../svg/solid/info.svg';
import { ReactComponent as InfoCircleSolid } from '../../svg/solid/info-circle.svg';
import { ReactComponent as InfoSquareSolid } from '../../svg/solid/info-square.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: InfoLight,
        regular: InfoRegular,
        solid: InfoSolid,
    },
    circle: {
        light: InfoCircleLight,
        regular: InfoCircleRegular,
        solid: InfoCircleSolid,
    },
    square: {
        light: InfoSquareLight,
        regular: InfoSquareRegular,
        solid: InfoSquareSolid,
    },
};

export const Info: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
