import styled, { css } from 'styled-components';
import { Eye as IconEye } from '@legex/icons';
import React from 'react';

import { IStyledIconDefaultProps, getDefaultIconStyled } from '../common';
import { DEFAULT_THEME } from '../../../common';

const Eye = (props) => {
    return <IconEye weight="light" {...props} />;
};

export const StyledEyeSolid = styled(Eye)<IStyledIconDefaultProps>`
    ${({ theme, disabled }) => css`
        display: inline-flex;
        vertical-align: middle;
        ${getDefaultIconStyled(theme, disabled)}
    `}
`;

StyledEyeSolid.defaultProps = {
    theme: DEFAULT_THEME,
};
