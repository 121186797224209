import styled, { css } from 'styled-components';

import { SwitchSlider } from './SwitchSlider';
import type { SwitchSize } from './SwitchSlider';
import { CIRCLE_TRANSLATE_X_XL, CIRCLE_TRANSLATE_X_XS } from './constants';

interface ISwitchInputProps {
    $size: SwitchSize;
}

export const SwitchInput = styled.input<ISwitchInputProps>`
    display: none;
    &:checked + ${SwitchSlider} {
        &:before {
            transform: ${({ $size }) =>
                $size === 'xs'
                    ? css`translateX(${CIRCLE_TRANSLATE_X_XS}px);`
                    : css`translateX(${CIRCLE_TRANSLATE_X_XL}px);`};
        }
    }
`;
