import styled, { css } from 'styled-components';
import { Times } from '@legex/icons';

import { getDefaultIconStyled, IStyledIconDefaultProps } from '../common';
import { DEFAULT_THEME } from '../../../common';

export const StyledClose = styled(Times)<IStyledIconDefaultProps>`
    ${({ theme, disabled }) => css`
        position: relative;
        display: inline-flex;
        vertical-align: middle;
        box-sizing: border-box;
        ${getDefaultIconStyled(theme, disabled)}
    `}
`;

StyledClose.defaultProps = {
    theme: DEFAULT_THEME,
};
