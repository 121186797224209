import React from 'react';

import { ReactComponent as MapLight } from '../../svg/light/map-marked-alt.svg';
import { ReactComponent as MapRegular } from '../../svg/regular/map-marked-alt.svg';
import { ReactComponent as MapSolid } from '../../svg/solid/map-marked-alt.svg';
import { ICommonIconResolver, IIconWrapped, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: MapLight,
    regular: MapRegular,
    solid: MapSolid,

};

export const Map: IIconWrapped = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
