import styled, { css } from 'styled-components';

import { Text1, Text2 } from '../../Typography';
import { DEFAULT_THEME } from '../../common';

import {
    TabsSize,
    TAB_PADDING_LEFT_WITH_ICON,
    TAB_PADDING_RIGHT_WITH_BADGE,
    TAB_PADDING,
} from './constants';

interface ITabContentProps {
    icon: boolean;
    badge: boolean;
}

const commonTabContentStyles = (icon: boolean, badge: boolean) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: ${badge ? TAB_PADDING_RIGHT_WITH_BADGE : TAB_PADDING};
    padding-left: ${icon ? TAB_PADDING_LEFT_WITH_ICON : TAB_PADDING};
`;

const TabContentBig = styled(Text1).attrs({ tabIndex: -1 })<ITabContentProps>`
    ${({ icon, badge }) => commonTabContentStyles(icon, badge)}
`;

TabContentBig.defaultProps = {
    theme: DEFAULT_THEME,
};

const TabContentSmall = styled(Text2).attrs({ tabIndex: -1 })<ITabContentProps>`
    ${({ icon, badge }) => commonTabContentStyles(icon, badge)}
`;

TabContentSmall.defaultProps = {
    theme: DEFAULT_THEME,
};

export const Content = (size: TabsSize) => (size === 'xs' ? TabContentSmall : TabContentBig);
