import { primary } from './primary';
import { secondary } from './secondary';
import { ghost } from './ghost';
import { warning } from './warning';
import { danger } from './danger';
import { success } from './success';

export const KIND_STYLES = {
    primary,
    secondary,
    ghost,
    warning,
    danger: danger,
    success,
};
