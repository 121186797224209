export type ModalSize = 'xxl' | 'xl' | 'xs' | 'xxs';

export const SIZES_WIDTH: { [key in ModalSize]: number } = {
    xxl: 800,
    xl: 592,
    xs: 488,
    xxs: 384,
};

export const SIZES_HEIGHT: { [key in ModalSize]: number } = {
    xxl: 84,
    xl: 72,
    xs: 56,
    xxs: 48,
};

export const PADDING_LEFT = '24px';
export const PADDING_TOP = '20px';
export const PADDING_RIGHT = '16px';
export const MOBILE_PADDING_LEFT = '16px';
export const MOBILE_PADDING_TOP = '24px';
export const MOBILE_PADDING_RIGHT = '8px';
export const SCROLL_PADDING_RIGHT = '8px';
export const HEADER_MARGIN_BOTTOM = '16px';
export const CONTENT_MARGIN_BOTTOM = '24px';
export const BUTTONS_PADDING_BOTTOM = '24px';
export const BUTTONS_MARGIN_BETWEEN = '16px';
export const BUTTONS_HEIGHT = '40px';
