import styled, { css } from 'styled-components';

import type { Size } from '../../common';

import { FLAG_WIDTH_SCALE_XL, FLAG_WIDTH_SCALE_XS } from './constants';

export const StyledSingleValue = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export interface IIconWrapper {
    size: Size;
}

export const IconWrapper = styled.div<IIconWrapper>`
    ${({ size }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(
            ${size === 'xs' ? FLAG_WIDTH_SCALE_XS : FLAG_WIDTH_SCALE_XL},
            ${size === 'xs' ? FLAG_WIDTH_SCALE_XS : FLAG_WIDTH_SCALE_XL}
        );
    `};
`;
