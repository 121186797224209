import styled, { css } from 'styled-components';
import { Plus as IconPlus } from '@legex/icons';
import React from 'react';

import { DEFAULT_THEME } from '../../../common';
import { getDefaultIconStyled, IStyledIconDefaultProps } from '../common';

const Plus = (props) => {
    return <IconPlus weight="light" {...props} />;
};

export const StyledPlusOutline = styled(Plus)<IStyledIconDefaultProps>`
    ${({ theme, disabled }) => css`
        ${getDefaultIconStyled(theme, disabled)}
    `}
`;

StyledPlusOutline.defaultProps = {
    theme: DEFAULT_THEME,
};
