import React, { ComponentType, createRef, PureComponent, RefObject } from 'react';
import ReactDOM from 'react-dom';
import { Times } from '@legex/icons';

import { Text2 } from '../../Typography';
import { containNode, getContainingBlockOffset, getScrollableParents } from '../../common/utils';

import { IconClose } from './IconClose';
import { Wrapper } from './Wrapper';
import { SIZES } from './constants';
import { Informer } from './Informer';
import { findDirection, findAlign, calculatePosition } from './utils';
import type { Size, Direction, Align } from './constants';

export interface IInformerHOCProps {
    /** Текст информера */
    message: string;
    /** Направление информера */
    direction?: Direction;
    /** Выравнивание информера */
    align?: Align;
    /** Размер информера */
    size?: Size;
    /** Видимость информера */
    informerVisible?: boolean;
    /** Обработчик скрытия, в котором необходимо непосредственно установить informerVisible */
    onRequestHide?: () => void;
    /** Отступ информера */
    offsetInformer?: number;
    /** Имя класса для переопределения стилей */
    className?: string;
    /** data-test-id атрибут для тестирования компонента */
    dataTestId?: string;
    /** Контейнер, в котором происходит размещение информера.
     * В отличие от TooltipHOC, информер по дизайну всегда имеет target, относительно
     * которого будет происходить позиционирование - это оборачиваемый компонент (иконка и т.п.) */
    container?: Element | null;
}

interface IInformerHOCState {
    pointerDirection: Direction;
    pointerAlign: Align;
    posX: number;
    posY: number;
}

const DEFAULT_POINTER_DIRECTION: Direction = 'bottom';
const DEFAULT_POINTER_ALIGN: Align = 'left';

export const createInformerHOC = <P extends Object>(WrappedComponent: ComponentType<P>) =>
    class InformerHOC extends PureComponent<P & IInformerHOCProps, IInformerHOCState> {
        informerRef: RefObject<HTMLDivElement>;
        wrapperRef: RefObject<HTMLDivElement>;
        scrollableParents?: Array<Element> = undefined;

        constructor(props: P & IInformerHOCProps) {
            super(props);
            this.informerRef = createRef();
            this.wrapperRef = createRef();

            this.state = {
                pointerDirection: DEFAULT_POINTER_DIRECTION,
                pointerAlign: DEFAULT_POINTER_ALIGN,
                posX: 0,
                posY: 0,
            };
        }

        componentDidMount() {
            document.addEventListener('click', this.handleDocumentClick.bind(this));
            window.addEventListener('scroll', this.onHide);
            window.addEventListener('resize', this.onHide);
            this.changePosition();
        }

        componentDidUpdate(prevProps: IInformerHOCProps, _: IInformerHOCState) {
            if (!this.scrollableParents && this.informerRef.current) {
                this.scrollableParents = getScrollableParents(this.informerRef.current);
                this.scrollableParents?.forEach((el) => el.addEventListener('scroll', this.onHide));
            }
            if (prevProps.informerVisible === this.props.informerVisible) return;
            this.changePosition();
        }

        componentWillUnmount() {
            document.removeEventListener('click', this.handleDocumentClick.bind(this));
            window.removeEventListener('scroll', this.onHide);
            window.removeEventListener('resize', this.onHide);
            this.scrollableParents &&
                this.scrollableParents.forEach((el) =>
                    el.removeEventListener('scroll', this.onHide)
                );
        }

        handleDocumentClick(e: MouseEvent) {
            if (this.wrapperRef.current && !containNode(this.wrapperRef.current, e.target)) {
                this.onHide();
            }
        }

        changePosition = () => {
            if (!this.props.informerVisible) return;
            const informerNode = this.informerRef.current;
            const wrapperNode = this.wrapperRef.current;
            if (!informerNode || !wrapperNode) return;

            const informerRect = informerNode.getBoundingClientRect();
            const wrapperRect = wrapperNode.getBoundingClientRect();

            const direction: Direction | undefined =
                this.props.direction || findDirection(informerRect, wrapperRect);
            if (!direction) return;

            const align: Align | undefined =
                this.props.align || findAlign(informerRect, wrapperRect);
            if (!align) return;

            const { parentTop, parentLeft } = getContainingBlockOffset(informerNode);
            const offset = {
                width: wrapperRect.left - parentLeft,
                height: wrapperRect.top - parentTop,
            };
            const informerCoords = calculatePosition(
                direction,
                align,
                informerRect,
                wrapperRect,
                offset
            );
            if (!informerCoords) return;

            this.setState({
                pointerDirection: direction,
                pointerAlign: align,
                posX: informerCoords.posX,
                posY: informerCoords.posY,
            });
        };

        handleHide = (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            this.onHide();
        };

        onHide = () => {
            this.props.onRequestHide && this.props.onRequestHide();
        };

        renderInformer() {
            const { message, size, informerVisible }: IInformerHOCProps = this.props;
            const { pointerDirection, pointerAlign, posX, posY } = this.state;
            return (
                <Informer
                    width={SIZES[size || 'big']}
                    pointerDirection={pointerDirection}
                    pointerAlign={pointerAlign}
                    position={{
                        posX: posX,
                        posY: posY,
                    }}
                    ref={this.informerRef}
                    visible={!!informerVisible}
                    offsetInformer={this.props.offsetInformer}
                >
                    <IconClose onClick={this.handleHide} markerOffset={6}>
                        <Times width={18} height={18} weight="light" />
                    </IconClose>
                    <Text2>{message}</Text2>
                </Informer>
            );
        }

        render() {
            const { className, dataTestId, container }: IInformerHOCProps = this.props;

            return (
                <Wrapper ref={this.wrapperRef} className={className} data-test-id={dataTestId}>
                    <WrappedComponent {...this.props} />
                    {container
                        ? ReactDOM.createPortal(this.renderInformer(), container)
                        : this.renderInformer()}
                </Wrapper>
            );
        }
    };
