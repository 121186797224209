import styled, { css } from 'styled-components';
import type { ReactNode } from 'react';
import { Check, Error, Info, Times } from '@legex/icons';

import { DEFAULT_THEME } from '../../common';

import { IconWrapper } from './IconWrapper';
import { BORDER_RADIUS, STATIC_PADDING } from './constants';
import { getColor, getBackgroundColor } from './utils';

export type NotificationKind = 'info' | 'warning' | 'success' | 'error';

export const NOTIFICATION_ICONS = {
    info: Info,
    warning: Error,
    success: Check,
    error: Times,
};

export interface INotificationProps {
    /** Текст короткого оповещения */
    children: ReactNode;
    /** Вид оповещения */
    kind?: NotificationKind;
    /** Заголовок оповещения */
    header?: string;
    /** Ширина оповещения */
    width?: number | string;
    /** Имя класса для переопределения стилей */
    className?: string;
    /** data-test-id атрибут для тестирования компонента */
    dataTestId?: string;
}

export interface INotificationComponentProps {
    kind: NotificationKind;
    width: number | string;
}

export const NotificationComponent = styled.div<INotificationComponentProps>`
    position: relative;
    padding: ${STATIC_PADDING};
    box-sizing: border-box;

    ${({ theme, kind, width }) => css`
        background-color: ${getBackgroundColor(kind, theme)};
        border-left: 4px solid ${getColor(kind, theme)};
        border-radius: ${BORDER_RADIUS}px;
        width: ${typeof width === 'number' ? `${width}px` : width};
        ${IconWrapper} {
            & svg {
                fill: ${getColor(kind, theme)};
                width: 18px;
                height: 18px;
            }
        }
    `}
`;

NotificationComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
