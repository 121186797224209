import React from 'react';

import { ReactComponent as PlusLight } from '../../svg/light/plus.svg';
import { ReactComponent as PlusCircleLight } from '../../svg/light/plus-circle.svg';
import { ReactComponent as PlusSquareLight } from '../../svg/light/plus-square.svg';
import { ReactComponent as PlusRegular } from '../../svg/regular/plus.svg';
import { ReactComponent as PlusCircleRegular } from '../../svg/regular/plus-circle.svg';
import { ReactComponent as PlusSquareRegular } from '../../svg/regular/plus-square.svg';
import { ReactComponent as PlusSolid } from '../../svg/solid/plus.svg';
import { ReactComponent as PlusCircleSolid } from '../../svg/solid/plus-circle.svg';
import { ReactComponent as PlusSquareSolid } from '../../svg/solid/plus-square.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: PlusLight,
        regular: PlusRegular,
        solid: PlusSolid,
    },
    circle: {
        light: PlusCircleLight,
        regular: PlusCircleRegular,
        solid: PlusCircleSolid,
    },
    square: {
        light: PlusSquareLight,
        regular: PlusSquareRegular,
        solid: PlusSquareSolid,
    },
};

export const Plus: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
