import type { IOpacity } from './interfaces';

export const OPACITY: IOpacity = {
    whiteHover: 'rgba(255, 255, 255, 0.08)',
    whiteFocus: 'rgba(255, 255, 255, 0.14)',
    whitePressed: 'rgba(255, 255, 255, 0.2)',
    whiteMedium: 'rgba(255, 255, 255, 0.6)',
    blackHover: 'rgba(0, 0, 0, 0.05)',
    blackFocus: 'rgba(0, 0, 0, 0.1)',
    blackPressed: 'rgba(0, 0, 0, 0.16)',
    blackMedium: 'rgba(0, 0, 0, 0.4)',
    black10: 'rgba(0, 0, 0, 0.1)',
    black20: 'rgba(0, 0, 0, 0.2)',
    black30: 'rgba(0, 0, 0, 0.3)',
    black40: 'rgba(0, 0, 0, 0.4)',
};
