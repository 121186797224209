import styled, { css } from 'styled-components';
import { Error } from '@legex/icons';

import { DEFAULT_THEME } from '../../../common';

export interface IStyledErrorSolidProps {
    $active: boolean;
}

export const StyledError = styled(Error)<IStyledErrorSolidProps>`
    ${({ $active, theme }) => css`
        position: relative;
        display: inline-flex;
        vertical-align: middle;
        box-sizing: border-box;
        fill: ${$active ? theme.color.neutral[50] : theme.color.danger[60]};
  `}
`;

StyledError.defaultProps = {
    theme: DEFAULT_THEME,
};
