import React from 'react';

import { ReactComponent as CalendarLight } from '../../svg/light/calendar-alt.svg';
import { ReactComponent as CalendarRegular } from '../../svg/regular/calendar-alt.svg';
import { ReactComponent as CalendarSolid } from '../../svg/solid/calendar-alt.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: CalendarLight,
    regular: CalendarRegular,
    solid: CalendarSolid,
};

export const Calendar: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
