import styled from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { BORDER_RADIUS, HEIGHT } from './constants';

export const Bar = styled.div`
    background: ${({ theme }) => theme.color.neutral[10]};
    border-radius: ${BORDER_RADIUS}px;
    height: ${HEIGHT}px;
    overflow: hidden;
`;

Bar.defaultProps = {
    theme: DEFAULT_THEME,
};
