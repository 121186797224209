import React from 'react';

import { ReactComponent as EllipsisHLight } from '../../svg/light/ellipsis-h.svg';
import { ReactComponent as EllipsisHRegular } from '../../svg/regular/ellipsis-h.svg';
import { ReactComponent as EllipsisHSolid } from '../../svg/solid/ellipsis-h.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: EllipsisHLight,
    regular: EllipsisHRegular,
    solid: EllipsisHSolid,
};

export const EllipsisH: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
