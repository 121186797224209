import styled from 'styled-components';

import { HEIGHT_PAGE, HEIGHT_TOP, MIN_WIDTH } from './constants';

const HEIGHT = {
    page: HEIGHT_PAGE,
    top: HEIGHT_TOP,
};

export type Kind = 'page' | 'top';

export const ProgressBarComponent = styled.div<{ kind: Kind }>`
    height: ${({ kind }) => HEIGHT[kind]}px;
    min-width: ${MIN_WIDTH}px;
    width: 100%;
`;
