import { css } from 'styled-components';

import type { ITheme } from '../../../themes';
import { BOX_SHADOW } from "../constants";
import { transparentize } from "polished";

export const ghost = (theme: ITheme) => css`
    background-color: transparent;
    border-color: transparent;
    color: ${theme.color.primary[60]};

    & div svg {
        fill: ${theme.color.primary[60]};
    }

    &:focus {
        &::after {
            border-color: transparent;
        }
    }

    &:hover {
        background-clip: padding-box;
        box-shadow: ${BOX_SHADOW} ${transparentize(0.4, theme.color.neutral[50])};
    }

    &:active {
        box-shadow: none;
        background-clip: padding-box;
        background-color: ${theme.color.neutral[10]};
        border-color: ${theme.color.neutral[10]};
    }

    &:disabled {
        box-shadow: none;
        background-color: transparent;
        border-color: transparent;
        color: ${theme.color.neutral[30]};

        & div svg {
            fill: ${theme.color.neutral[30]};
        }
    }
`;
