export type OverflowMenuSize = 'xl' | 'xs';
export type OverflowMenuAlignment = 'left' | 'right';

export const MENU_SIZES = {
    xl: 24,
    xs: 16,
};
export const MENU_MARGIN_TOP = 8;
export const MENU_WIDTH = 290;
export const BORDER_RADIUS = 4;
