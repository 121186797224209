import React, { ChangeEvent, FocusEvent, useState } from 'react';
import { Search } from '@legex/icons';

import { getIconSize } from '../../common';
import { StyledError } from '../../Text/StyledError';
import { StyledClose } from '../../Text/StyledСlose';
import { Input as BaseInput } from '../../BaseField';
import { StyledSearch } from '../StyledSearch';

import { IconWrapper } from './style';

export const Input: any = ({
    selectProps: { inputProps },
    onChange,
    onBlur,
    onFocus,
    defaultValue,
}) => {
    const { disabled, value, size = 'big', status, clearable, setFocus } = inputProps;
    const [errorActive, setErrorActive] = useState<boolean>(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
        onChange(event, value);
        setFocus(true);
        inputProps.onChange(event, value);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>, value: string) => {
        setErrorActive(false);
        setFocus(false);
        onBlur(event);

        inputProps.onBlur?.(event, value);
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        onFocus(event);
        setErrorActive(true);
        if (inputProps.onFocus) {
            inputProps.onFocus(event);
        }
    };

    const handleClickClearIcon = (event: any): void => {
        !disabled && handleChange(event, '');
    };

    const iconSize = getIconSize(size);
    const iconServices = (
        <IconWrapper>
            {status === 'error' && !disabled ? (
                <StyledError width={iconSize} $active={errorActive} />
            ) : (
                clearable && (
                    <StyledClose
                        width={iconSize}
                        disabled={disabled}
                        onClick={handleClickClearIcon}
                    />
                )
            )}
            <StyledSearch disabled={disabled}>
                <Search width={iconSize} height={iconSize} />
            </StyledSearch>
        </IconWrapper>
    );

    return (
        <div style={{ width: '100%' }}>
            {defaultValue && !value && (
                <BaseInput
                    withTooltip
                    size={size}
                    status={status}
                    disabled={disabled}
                    iconServices={iconServices}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            )}
            <BaseInput
                withTooltip
                size={size}
                status={status}
                disabled={disabled}
                iconServices={iconServices}
                value={value}
                {...inputProps}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    );
};
