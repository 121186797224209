import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { KIND_STYLES } from './kind-styles';
import { calculatePadding, getWidth, BUTTON_SIZE } from './utils';
import { OUTLINE_BORDER_RADIUS, OUTLINE_OFFSET, OUTLINE_SIZE } from "./constants";

export type Color = 'primary' | 'secondary' | 'ghost' | 'success' | 'warning' | 'danger';
export type Size = 'xl' | 'xs';
export type ButtonBasicProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface IButtonComponentProps extends ButtonBasicProps {
    fullWidth: boolean;
    color: Color;
    multipleChildren: boolean;
    onlyIcon: boolean;
    rightIcon: boolean;
    size: Size;
}

export const ButtonComponent = styled.button<IButtonComponentProps>`
    ${({ fullWidth, color, multipleChildren, onlyIcon, rightIcon, size, theme }) => css`
        align-items: center;
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        height: ${BUTTON_SIZE[size]}px;
        justify-content: center;
        outline: none;
        padding: ${onlyIcon ? 0 : calculatePadding({ multipleChildren, rightIcon, size, theme })};
        position: relative;
        width: ${getWidth({ fullWidth, onlyIcon, size })};
        ${KIND_STYLES[color](theme)};

        &:focus {
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: ${OUTLINE_OFFSET};
                bottom: ${OUTLINE_OFFSET};
                left: ${OUTLINE_OFFSET};
                right: ${OUTLINE_OFFSET};
                border-radius: ${OUTLINE_BORDER_RADIUS};
                border: ${OUTLINE_SIZE} solid ${theme.color.primary[30]};
            }
        }

        &:disabled {
            cursor: default;
        }
    `};
`;

ButtonComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
