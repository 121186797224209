import React from 'react';

import { ReactComponent as QuestionLight } from '../../svg/light/Question.svg';
import { ReactComponent as QuestionCircleLight } from '../../svg/light/Question-circle.svg';
import { ReactComponent as QuestionSquareLight } from '../../svg/light/Question-square.svg';
import { ReactComponent as QuestionRegular } from '../../svg/regular/Question.svg';
import { ReactComponent as QuestionCircleRegular } from '../../svg/regular/Question-circle.svg';
import { ReactComponent as QuestionSquareRegular } from '../../svg/regular/Question-square.svg';
import { ReactComponent as QuestionSolid } from '../../svg/solid/Question.svg';
import { ReactComponent as QuestionCircleSolid } from '../../svg/solid/Question-circle.svg';
import { ReactComponent as QuestionSquareSolid } from '../../svg/solid/Question-square.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: QuestionLight,
        regular: QuestionRegular,
        solid: QuestionSolid,
    },
    circle: {
        light: QuestionCircleLight,
        regular: QuestionCircleRegular,
        solid: QuestionCircleSolid,
    },
    square: {
        light: QuestionSquareLight,
        regular: QuestionSquareRegular,
        solid: QuestionSquareSolid,
    },
};

export const Question: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
