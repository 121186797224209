import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { Z_INDEXES } from '../../common';
import { MEDIA_MOBILE } from '../../common/media';

import { INotificationComponentProps, NotificationComponent } from './NotificationComponent';
import { IconWrapper } from './IconWrapper';
import { IconClose } from './IconClose';
import { DYNAMIC_PADDING, MOBILE_DYNAMIC_PADDING, MOBILE_MARGIN, TOP_MARGIN } from './constants';

export interface IDynamicNotificationComponentProps extends INotificationComponentProps {
    mobile?: boolean;
    managed?: boolean;
    top?: number;
    left?: number;
    right?: number;
}

export const DynamicNotificationComponent = styled(
    NotificationComponent
)<IDynamicNotificationComponentProps>`
    padding: ${DYNAMIC_PADDING};
    pointer-events: auto;

    ${({ top, left, right, managed }) =>
        managed
            ? css`
                  ${top !== undefined ? `margin-top: ${top}px;` : ''}
                  ${left !== undefined ? `margin-left: ${left}px;` : ''}
                  ${right !== undefined
                      ? css`
                            margin-left: auto;
                            margin-right: ${right}px;
                        `
                      : null}
                  &:not(:first-child) {
                      margin-top: ${TOP_MARGIN};
                  }

                  @media ${MEDIA_MOBILE} {
                      margin-top: ${MOBILE_MARGIN};
                      margin-left: ${MOBILE_MARGIN};
                  }
              `
            : css`
                  position: fixed;
                  z-index: ${Z_INDEXES.NOTIFICATION};

                  ${top !== undefined ? `top: ${top}px;` : ''}
                  ${left !== undefined ? `left: ${left}px;` : ''}
                  ${right !== undefined ? `right: ${right}px;` : ''}
                  &:not(:first-child) {
                      top: ${TOP_MARGIN};
                  }

                  @media ${MEDIA_MOBILE} {
                      top: ${MOBILE_MARGIN};
                      left: ${MOBILE_MARGIN};
                  }
              `} @media ${MEDIA_MOBILE} {
        width: calc(100% - 2 * ${MOBILE_MARGIN});
        padding: ${MOBILE_DYNAMIC_PADDING};

        ${IconWrapper} {
            display: none;
        }

        ${({ mobile }) =>
            mobile &&
            css`
                ${IconClose} {
                    display: none;
                }
            `}
    }
    ${({ theme }) =>
        css`
            box-shadow: 0 0 15px 0 ${transparentize(0.2, theme.color.neutral[50])};

            ${IconClose} svg {
                fill: ${theme.color.neutral[50]};

                &:hover {
                    fill: ${theme.color.primary[60]};
                }
            }
        `}
`;
