import { css } from 'styled-components';
import { transparentize } from 'polished';

import type { ITheme } from '../../../themes';
import { BOX_SHADOW } from '../constants';
import { IColor } from '../../../themes/common';

export const commonStyle = (style: keyof IColor) => (theme: ITheme) => css`
    background-color: ${theme.color[style][50]};
    border-color: ${theme.color[style][50]};
    color: ${theme.color.neutral[0]};

    & div svg {
        fill: ${theme.color.neutral[0]};
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        box-shadow: ${BOX_SHADOW} ${transparentize(0.4, theme.color[style][50])};
        background-color: ${theme.color[style][50]};
        border-color: ${theme.color[style][50]};
    }

    &:active {
        background-color: ${theme.color[style][60]};
        border-color: ${theme.color[style][60]};
        box-shadow: none;
    }

    &:disabled {
        background-color: ${theme.color[style][30]};
        border-color: ${theme.color[style][30]};
        box-shadow: none;

        & div svg {
            fill: ${theme.color[style][30]};
        }
    }
`;
