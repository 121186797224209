import React, { FC } from 'react';

import { Header } from '../common/Header';
import { Message } from '../common/Message';
import { DynamicNotification } from '../common/DynamicNotification';
import type { IDynamicNotificationProps } from '../common/DynamicNotification';

export interface ILineNotificationProps extends IDynamicNotificationProps {
    /** Принудительно тёмный фон */
    showDarkBackground?: boolean;
}

export const LineNotification: FC<ILineNotificationProps> = ({
    children,
    header,
    showDarkBackground,
    ...props
}) => (
    <DynamicNotification top={0} left={0} {...props}>
        {!!header && <Header>{header}</Header>}
        <Message>{children}</Message>
    </DynamicNotification>
);
