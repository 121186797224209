import styled from 'styled-components';

import { DEFAULT_THEME } from '../../common';

export const DatePickerInputComponent = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
`;

DatePickerInputComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
