const FONT_FAMILY = 'Legex';

interface IFontSize {
    11: string;
    12: string;
    13: string;
    14: string;
    16: string;
    18: string;
    24: string;
}

const FONT_SIZE: IFontSize = {
    11: '11px',
    12: '12px',
    13: '13px',
    14: '14px',
    16: '16px',
    18: '18px',
    24: '24px',
};

interface IFontWeight {
    thin: number;
    light: number;
    extraLight: number;
    regular: number;
    bold: number;
    extraBold: number;
}

const FONT_WEIGHT: IFontWeight = {
    thin: 100,
    light: 200,
    extraLight: 300,
    regular: 400,
    bold: 600,
    extraBold: 700,
};

interface ILineHeight {
    11: string;
    13: string;
    14: string;
    15: string;
    16: string;
    17: string;
    18: string;
    19: string;
    20: string;
    21: string;
    24: string;
    25: string;
    30: string;
    31: string;
}

const LINE_HEIGHT: ILineHeight = {
    11: '11px',
    13: '13px',
    14: '14px',
    15: '15px',
    16: '16px',
    17: '17px',
    18: '18px',
    19: '18px',
    20: '20px',
    21: '21px',
    24: '24px',
    25: '25px',
    30: '30px',
    31: '31px',
};

export interface ITypography {
    fontFamily: string;
    fontSize: IFontSize;
    fontWeight: IFontWeight;
    lineHeight: ILineHeight;
}

export const TYPOGRAPHY: ITypography = {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZE,
    fontWeight: FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
};
