import React from 'react';

import { ReactComponent as ExclamationLight } from '../../svg/light/exclamation.svg';
import { ReactComponent as ExclamationCircleLight } from '../../svg/light/exclamation-circle.svg';
import { ReactComponent as ExclamationSquareLight } from '../../svg/light/exclamation-square.svg';
import { ReactComponent as ExclamationRegular } from '../../svg/regular/exclamation.svg';
import { ReactComponent as ExclamationCircleRegular } from '../../svg/regular/exclamation-circle.svg';
import { ReactComponent as ExclamationSquareRegular } from '../../svg/regular/exclamation-square.svg';
import { ReactComponent as ExclamationSolid } from '../../svg/solid/exclamation.svg';
import { ReactComponent as ExclamationCircleSolid } from '../../svg/solid/exclamation-circle.svg';
import { ReactComponent as ExclamationSquareSolid } from '../../svg/solid/exclamation-square.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: ExclamationLight,
        regular: ExclamationRegular,
        solid: ExclamationSolid,
    },
    circle: {
        light: ExclamationCircleLight,
        regular: ExclamationCircleRegular,
        solid: ExclamationCircleSolid,
    },
    square: {
        light: ExclamationSquareLight,
        regular: ExclamationSquareRegular,
        solid: ExclamationSquareSolid,
    },
};

export const Error: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
