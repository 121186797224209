import type {
    IBackground,
    IColor,
    IDanger,
    INeutral,
    IPrimary,
    ISuccess,
    IWarning,
    IAttention, IFontColor,
} from '../common';
import { OPACITY } from '../common';

const NEUTRAL: INeutral = {
    0: '#FFFFFF',
    5: '#F3F4F6',
    10: '#E5E7EB',
    20: '#D6D8DE',
    30: '#B1B5BB',
    40: '#9A9EA5',
    50: '#7B7E86',
    60: '#696C73',
    70: '#505258',
    80: '#37383C',
    90: '#22242A',
    100: '#000000',
};

const PRIMARY: IPrimary = {
    10: '#fbfdfe',
    20: '#c5d9f2',
    30: '#8fb5e6',
    40: '#5991da',
    50: '#2c6ec4',
    60: '#20508e',
    70: '#1b4479',
    80: '#173964',
    90: '#122d50',
    100: '#0d213b',
};

const PRIMARY_OLD: IPrimary = {
    10: '#fbfbff',
    20: '#e8e6fc',
    30: '#beb9f6',
    40: '#958bf1',
    50: '#6b5eeb',
    60: '#4B40B7',
    70: '#4035b3',
    80: '#372d99',
    90: '#2e2778',
    100: '#1c1f4a',
};

const DANGER: IDanger = {
    10: '#FFEFEF',
    20: '#FFD4D4',
    30: '#FFA7A7',
    40: '#FF7C7C',
    50: '#F64E4E',
    60: '#D92020',
    70: '#A71212',
    80: '#780A0A',
    90: '#520606',
    100: '#320000',
};

const SUCCESS: ISuccess = {
    10: '#eefcf2',
    20: '#b3f0c6',
    30: '#77e49a',
    40: '#3cd86e',
    50: '#22AA4E',
    60: '#1d9042',
    70: '#187737',
    80: '#135d2b',
    90: '#0e441f',
    100: '#092a14',
};

const WARNING: IWarning = {
    10: '#FFF1E5',
    20: '#FFD7C3',
    30: '#FFB799',
    40: '#FF8D64',
    50: '#FF5C22',
    60: '#D63F09',
    70: '#A63208',
    80: '#7A2503',
    90: '#591C04',
    100: '#3d1100',
};

const ATTENTION: IAttention = {
    10: '#FEF5D7',
    20: '#FEEEB8',
    30: '#FEE189',
    40: '#FDD14C',
    50: '#FFC400',
    60: '#d6a500',
    70: '#ad8500',
    80: '#856600',
    90: '#5c4700',
    100: '#332700',
};

const BACKGROUND: IBackground = {
    color: '#ffffff',
};

const BACKGROUND_ALT: IBackground = {
    color: '#f5f5f9',
};

const FONT_COLOR: IFontColor = {
    color: '#19191c',
    inverseColor: '#dbd9dd',
};

const FONT_COLOR_ALT: IFontColor = {
    color: '#11122c',
    inverseColor: '#d4cdfb',
};

export const COLOR: IColor = {
    neutral: NEUTRAL,
    primary: PRIMARY,
    danger: DANGER,
    success: SUCCESS,
    warning: WARNING,
    opacity: OPACITY,
    attention: ATTENTION,
    background: BACKGROUND,
    backgroundAlt: BACKGROUND_ALT,
    fontColor: FONT_COLOR,
    fontColorAlt: FONT_COLOR_ALT,
};
