export type LinkSize = 'xl' | 'xs';
export type LinkKind = 'primary' | 'secondary';
export type LinkIconPosition = 'left' | 'right';
export type LinkTarget = '_self' | '_parent' | '_top' | '_blank';

export const LINK_ICON_PADDING = '6px';

export const SIZES = {
    xl: 24,
    xs: 20,
};
