import styled from 'styled-components';

import { Z_INDEXES, DEFAULT_THEME } from '../../common';

import { DROPDOWN_PADDING } from './constants';

interface ICalendarPopupComponentProps {
    position: {
        X: number;
        Y: number;
    };
}

export const CalendarPopupComponent = styled.div<ICalendarPopupComponentProps>`
    position: fixed;
    left: ${({ position: { X } }) => X}px;
    top: ${({ position: { Y } }) => Y + DROPDOWN_PADDING}px;
    z-index: ${Z_INDEXES.DATEPICKER_CALENDAR};
`;

CalendarPopupComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
