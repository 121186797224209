import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import {
    BORDER_RADIUS,
    BOX_HEIGHT,
    BOX_WIDTH,
    CHECKBOX_MARGIN,
    CHECKBOX_MARGIN_WITH_LABEL,
} from './constants';

interface IBoxProps {
    checked?: boolean;
    disabled?: boolean;
    error?: boolean;
    withLabel?: boolean;
}

export const Box = styled.div<IBoxProps>`
    ${({ theme, error, withLabel, disabled, checked }) => css`
        box-sizing: border-box;
        position: relative;
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${BOX_HEIGHT}px;
        outline: none;
        width: ${BOX_WIDTH}px;
        margin: ${
            withLabel
                ? `${CHECKBOX_MARGIN}px ${CHECKBOX_MARGIN_WITH_LABEL}px ${CHECKBOX_MARGIN}px ${CHECKBOX_MARGIN}px`
                : `${CHECKBOX_MARGIN}px`
        };
        background-color: transparent;
        border: 1px solid
            ${error ? theme.color.danger[60] : theme.color[checked ? 'primary' : 'neutral'][50]};
        border-radius: ${BORDER_RADIUS}px;
        ${
            disabled &&
            css`
                opacity: 0.4;
            `
        }}
    `}
`;

Box.defaultProps = {
    theme: DEFAULT_THEME,
};
