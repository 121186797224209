export const SIZES = {
    xl: 56,
    xs: 32,
};

export const CALENDAR_SIZES = {
    xl: 320,
    xs: 248,
};

export const CALENDAR_POPUP_Z_INDEX = 100;
export const DROPDOWN_PADDING = 15;
export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
