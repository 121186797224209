import React from 'react';

import { IIconProps, IIconWrappedProps } from './IIconProps';

export interface IWrappedIconResolver {
    none: {
        light: React.FC<React.SVGProps<SVGSVGElement>>;
        regular: React.FC<React.SVGProps<SVGSVGElement>>;
        solid: React.FC<React.SVGProps<SVGSVGElement>>;
    };
    circle: {
        light: React.FC<React.SVGProps<SVGSVGElement>>;
        regular: React.FC<React.SVGProps<SVGSVGElement>>;
        solid: React.FC<React.SVGProps<SVGSVGElement>>;
    };
    square: {
        light: React.FC<React.SVGProps<SVGSVGElement>>;
        regular: React.FC<React.SVGProps<SVGSVGElement>>;
        solid: React.FC<React.SVGProps<SVGSVGElement>>;
    };
}

export interface ICommonIconResolver {
    light: React.FC<React.SVGProps<SVGSVGElement>>;
    regular: React.FC<React.SVGProps<SVGSVGElement>>;
    solid: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const resolveWrappedIcon = (props: IIconWrappedProps, resolver: IWrappedIconResolver) => {
    return resolver[props.wrap || 'none'][props.weight || 'regular'];
};

export const resolveIcon = (props: IIconProps, resolver: ICommonIconResolver) => {
    return resolver[props.weight || 'regular'];
};
