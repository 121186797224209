import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { Box } from './Box';
import {
    BORDER_RADIUS,
    CHECKBOX_MIN_HEIGHT,
    OUTLINE_BORDER_RADIUS,
    OUTLINE_OFFSET,
    OUTLINE_SIZE,
} from './constants';
import { ICommonProps } from './common';
import { Mark } from './Mark';

export const CheckboxComponent = styled.div<ICommonProps>`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    outline: none;
    min-height: ${CHECKBOX_MIN_HEIGHT}px;
    ${({ disabled, theme, checked }) =>
        css`
            cursor: ${disabled ? 'default' : 'pointer'};
            ${disabled
                ? null
                : css`
                      &:hover {
                          ${Box} {
                              border-color: ${theme.color[checked ? 'primary' : 'neutral'][60]};

                              ${Mark} {
                                  background-color: ${theme.color[
                                      checked ? 'primary' : 'neutral'
                                  ][60]};
                              }
                          }
                      }

                      &:active {
                          ${Box} {
                              border-color: ${theme.color[checked ? 'primary' : 'neutral'][70]};

                              ${Mark} {
                                  background-color: ${theme.color[
                                      checked ? 'primary' : 'neutral'
                                  ][70]};
                              }
                          }
                      }

                      &:focus {
                          ${Box}:after {
                              outline: none;
                              content: '';
                              display: block;
                              position: absolute;
                              top: ${OUTLINE_OFFSET}px;
                              bottom: ${OUTLINE_OFFSET}px;
                              left: ${OUTLINE_OFFSET}px;
                              right: ${OUTLINE_OFFSET}px;
                              border-radius: ${OUTLINE_BORDER_RADIUS}px;
                              border: ${OUTLINE_SIZE}px solid ${theme.color.primary[30]};
                          }
                      }
                  `}
        `}
    }
`;

CheckboxComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
