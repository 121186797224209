import React from 'react';

import { ReactComponent as ChevronUpLight } from '../../svg/light/chevron-up.svg';
import { ReactComponent as ChevronUpCircleLight } from '../../svg/light/chevron-circle-up.svg';
import { ReactComponent as ChevronUpSquareLight } from '../../svg/light/chevron-square-up.svg';
import { ReactComponent as ChevronUpRegular } from '../../svg/regular/chevron-up.svg';
import { ReactComponent as ChevronUpCircleRegular } from '../../svg/regular/chevron-circle-up.svg';
import { ReactComponent as ChevronUpSquareRegular } from '../../svg/regular/chevron-square-up.svg';
import { ReactComponent as ChevronUpSolid } from '../../svg/solid/chevron-up.svg';
import { ReactComponent as ChevronUpCircleSolid } from '../../svg/solid/chevron-circle-up.svg';
import { ReactComponent as ChevronUpSquareSolid } from '../../svg/solid/chevron-square-up.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: ChevronUpLight,
        regular: ChevronUpRegular,
        solid: ChevronUpSolid,
    },
    circle: {
        light: ChevronUpCircleLight,
        regular: ChevronUpCircleRegular,
        solid: ChevronUpCircleSolid,
    },
    square: {
        light: ChevronUpSquareLight,
        regular: ChevronUpSquareRegular,
        solid: ChevronUpSquareSolid,
    },
};

export const ChevronUp: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
