import React, { ReactNode, ElementType, FC } from 'react';

import { LinkComponentAnchor, LinkComponentReact } from './LinkComponent';
import { CaptionXs } from './CaptionXs';
import { CaptionXl } from './CaptionXl';
import { IconContainer } from './IconContainer';
import { SIZES } from './constants';
import type { LinkKind, LinkSize, LinkIconPosition, LinkTarget } from './constants';

export interface ILinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    /** Текст ссылки */
    children: ReactNode;
    /** Гиперссылка React Router */
    to?: string;
    /** Ссылка представляет URL или якорь (#ID элемента на текущей странице) */
    href?: string;
    /** Иконка, отображаемая внутри ссылки слева или справа */
    icon?: ElementType;
    /** Вид ссылки */
    kind?: LinkKind;
    /** Размер ссылки */
    size?: LinkSize;
    /** Размещение иконки слева или справа текста ссылки */
    iconPosition?: LinkIconPosition;
    /** Где отобразить переход URL */
    target?: LinkTarget;
    /** Инвертировать цвет ссылки для отображения на тёмных фонах */
    inverse?: boolean;
    /** Отключение ссылки */
    disabled?: boolean;
    /** Имя класса для переопределения стилей */
    className?: string;
    /** data-test-id атрибут для тестирования компонента */
    dataTestId?: string;
}

export const Link: FC<ILinkProps> = ({
    children,
    to,
    href,
    icon,
    kind = 'primary',
    size = 'xl',
    target,
    iconPosition = icon ? 'right' : undefined,
    inverse = false,
    disabled = false,
    className,
    dataTestId,
    ...props
}: ILinkProps) => {
    const Caption = size === 'xl' ? CaptionXl : CaptionXs;
    const iconSize = SIZES[size];

    const LinkComponent = to ? LinkComponentReact : LinkComponentAnchor;

    return (
        <LinkComponent
            {...props}
            href={href}
            to={to as string}
            target={target}
            inverse={inverse}
            className={className}
            data-test-id={dataTestId}
            kind={kind}
            size={size}
            iconPosition={icon ? iconPosition : undefined}
            disabled={disabled}
        >
            {icon && iconPosition === 'left' && (
                <IconContainer size={size}>
                    {React.createElement(icon, { width: iconSize, height: iconSize })}
                </IconContainer>
            )}
            <Caption>{children}</Caption>
            {icon && iconPosition === 'right' && (
                <IconContainer size={size}>
                    {React.createElement(icon, { width: iconSize, height: iconSize })}
                </IconContainer>
            )}
        </LinkComponent>
    );
};
