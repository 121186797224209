// import { TimePicker } from '../TimePicker';

import { InputText } from './Text';
import { InputInformer } from './Informer';
import { InputPassword } from './Password';
import { InputNumber } from './Number';
import { InputRange } from './InputRange';
import { InputMask } from './Mask';
import { InputSuggest } from './Suggest';
import { InputPhoneCountry } from './PhoneCountry';
export type { IInputProps } from './BaseField';
export type { IInputMaskProps } from './Mask';
export type { IInputSuggestOption } from './Suggest';

export const Input = {
    Text: InputText,
    Informer: InputInformer,
    Password: InputPassword,
    Number: InputNumber,
    Range: InputRange,
    Mask: InputMask,
    Suggest: InputSuggest,
    PhoneCountry: InputPhoneCountry,
    // Time: TimePicker, TODO
};
