import {
    MASK_LINE_HEIGHT_XL,
    MASK_LINE_HEIGHT_XS,
    INPUT_MARGIN_TOP_XL,
    INPUT_MARGIN_TOP_XS,
} from '../constants';
import type { Size } from '../../common';

export const getMaskLineHeight = (size: Size) => {
    switch (size) {
        case 'xs':
            return MASK_LINE_HEIGHT_XS;
        default:
            return MASK_LINE_HEIGHT_XL;
    }
};

export const getInputMarginTop = (size: Size) => {
    switch (size) {
        case 'xs':
            return INPUT_MARGIN_TOP_XS;
        default:
            return INPUT_MARGIN_TOP_XL;
    }
};
