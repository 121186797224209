export enum Z_INDEXES {
    OVERLAY_SPINNER = (1 << 15) - 1, // maximum CSS integer value, which is supported by all browsers
    TOOLTIP = Z_INDEXES.OVERLAY_SPINNER,
    OVERLAY = Z_INDEXES.OVERLAY_SPINNER - 1,
    NOTIFICATION = Z_INDEXES.OVERLAY - 1,
    INFORMER = Z_INDEXES.OVERLAY - 2,
    DATEPICKER_CALENDAR = Z_INDEXES.OVERLAY - 3,
    MODAL = Z_INDEXES.OVERLAY - 4,
    INPUT = Z_INDEXES.OVERLAY - 5,
}
