import React from 'react';

import { ReactComponent as FileInvoiceDollarLight } from '../../svg/light/file-invoice-dollar.svg';
import { ReactComponent as FileInvoiceDollarRegular } from '../../svg/regular/file-invoice-dollar.svg';
import { ReactComponent as FileInvoiceDollarSolid } from '../../svg/solid/file-invoice-dollar.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: FileInvoiceDollarLight,
    regular: FileInvoiceDollarRegular,
    solid: FileInvoiceDollarSolid,
};

export const FileInvoiceDollar: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
