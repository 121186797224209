import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../../../common';
import { BORDER_RADIUS, OverflowMenuAlignment } from '../../constants';

interface IMenuWrapperProps {
    width: string | number;
    marginTop: string | number;
    align: OverflowMenuAlignment;
}

export const MenuWrapper = styled.div<IMenuWrapperProps>`
    position: absolute;
    top: 100%;
    box-sizing: border-box;
    z-index: 5;
    ${({ width, marginTop, align, theme }) => css`
        width: ${typeof width === 'number' ? `${width}px` : width};
        margin-top: ${typeof marginTop === 'number' ? `${marginTop}px` : marginTop};
        ${align}: 0;
        border-radius: ${BORDER_RADIUS}px;
        background-color: ${theme.color.neutral[0]};
        box-shadow: ${theme.shadow[15]};
    `}
`;

MenuWrapper.defaultProps = {
    theme: DEFAULT_THEME,
};
