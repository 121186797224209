import React from 'react';

import { ReactComponent as MinusLight } from '../../svg/light/minus.svg';
import { ReactComponent as MinusCircleLight } from '../../svg/light/minus-circle.svg';
import { ReactComponent as MinusSquareLight } from '../../svg/light/minus-square.svg';
import { ReactComponent as MinusRegular } from '../../svg/regular/minus.svg';
import { ReactComponent as MinusCircleRegular } from '../../svg/regular/minus-circle.svg';
import { ReactComponent as MinusSquareRegular } from '../../svg/regular/minus-square.svg';
import { ReactComponent as MinusSolid } from '../../svg/solid/minus.svg';
import { ReactComponent as MinusCircleSolid } from '../../svg/solid/minus-circle.svg';
import { ReactComponent as MinusSquareSolid } from '../../svg/solid/minus-square.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: MinusLight,
        regular: MinusRegular,
        solid: MinusSolid,
    },
    circle: {
        light: MinusCircleLight,
        regular: MinusCircleRegular,
        solid: MinusCircleSolid,
    },
    square: {
        light: MinusSquareLight,
        regular: MinusSquareRegular,
        solid: MinusSquareSolid,
    },
};

export const Minus: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
