import styled from 'styled-components';

import { markerStyle, DEFAULT_THEME } from '../../common';

import { ICON_CLOSE_RIGHT, ICON_CLOSE_TOP } from './constants';

export const IconClose = styled.div<{ inverse?: boolean }>`
    position: absolute;
    right: ${ICON_CLOSE_RIGHT}px;
    top: ${ICON_CLOSE_TOP}px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

IconClose.defaultProps = {
    theme: DEFAULT_THEME,
};
