import styled, { css } from 'styled-components';

import { LINE_HEIGHT_INPUT } from '../constants';
import { TYPOGRAPHY } from '../../../../Typography';
import { DEFAULT_THEME } from '../../../../common';
import type { ITheme } from '../../../../themes';
import type { Size } from '../../common';
interface ISpanMask {
    size: Size;
    disabled?: boolean;
    active?: boolean;
    symbolsFilled?: boolean;
    focused?: boolean;
}

export const getColorMask = (theme: ITheme, disabled = false, focused = false) => {
    if (disabled) return theme.color.neutral[30];
    if (focused) return theme.color.neutral[90];

    return theme.color.neutral[50];
};

export const SpanMask = styled.label<ISpanMask>`
    ${({ size, theme, disabled, active, symbolsFilled, focused }) => css`
        border-bottom: ${active || symbolsFilled
            ? 'none'
            : `0.7px solid ${getColorMask(theme, disabled, !focused)}`};
        white-space: pre;
        position: relative;
        z-index: ${!focused ? 10 : 'none'};
        font-family: ${TYPOGRAPHY.fontFamily};
        font-size: ${size === 'xs' ? TYPOGRAPHY.fontSize[13] : TYPOGRAPHY.fontSize[15]};
        line-height: ${size === 'xs' ? TYPOGRAPHY.lineHeight[20] : `${LINE_HEIGHT_INPUT}px`};
        font-weight: ${TYPOGRAPHY.fontWeight.regular};
        font-stretch: normal;
        font-style: normal;
        font-feature-settings: 'tnum';
        box-sizing: content-box;
        pointer-events: none;
        cursor: text;
        color: ${active ? 'transparent' : getColorMask(theme, disabled, focused)};
    `}
`;

SpanMask.defaultProps = {
    theme: DEFAULT_THEME,
};
