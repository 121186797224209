import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../../../common';
import { Text2, H4 } from '../../../../Typography';

export interface IHeaderTitleProps {
    disabled?: boolean;
}

export const HeaderTitle = styled(H4)<IHeaderTitleProps>`
    ${({ theme, disabled }) => css`
        ${disabled ? `color: ${theme.color.neutral[30]};` : ''}
        margin-bottom: 2px;
    `}
`;

HeaderTitle.defaultProps = {
    theme: DEFAULT_THEME,
};

export interface IHeaderSubtitleProps {
    disabled?: boolean;
}

export const HeaderSubtitle = styled(Text2)<IHeaderSubtitleProps>`
    ${({ theme, disabled }) => css`
        color: ${disabled ? theme.color.neutral[30] : theme.color.neutral[50]};
        margin-bottom: 16px;
    `}
`;

HeaderSubtitle.defaultProps = {
    theme: DEFAULT_THEME,
};
