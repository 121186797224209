import React from 'react';

import { ReactComponent as PhoneLight } from '../../svg/light/phone-alt.svg';
import { ReactComponent as PhoneRegular } from '../../svg/regular/phone-alt.svg';
import { ReactComponent as PhoneSolid } from '../../svg/solid/phone-alt.svg';
import { ICommonIconResolver, IIconWrapped, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: PhoneLight,
    regular: PhoneRegular,
    solid: PhoneSolid,

};

export const Phone: IIconWrapped = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
