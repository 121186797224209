import React from 'react';

import { ReactComponent as UserCircleLight } from '../../svg/light/user-circle.svg';
import { ReactComponent as UserCircleRegular } from '../../svg/regular/user-circle.svg';
import { ReactComponent as UserCircleSolid } from '../../svg/solid/user-circle.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: UserCircleLight,
    regular: UserCircleRegular,
    solid: UserCircleSolid,
};

export const UserCircle: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
