import styled from 'styled-components';

import type { Size } from '../../../common';
import { DEFAULT_THEME } from '../../../../../common';

export interface ICommonProps {
    size: Size;
}

export const SelectWrapper = styled.div<ICommonProps>`
    position: relative;
    background: ${({ theme }) => theme.color.neutral[0]};
    width: 100% !important;
    height: ${({ size }) => size}px;
`;

SelectWrapper.defaultProps = {
    theme: DEFAULT_THEME,
};
