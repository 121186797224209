import styled, { css } from 'styled-components';
import { DropzoneRootProps } from 'react-dropzone';

import { DEFAULT_THEME } from '../../../../common';

import { DropzoneText } from './DropzoneText';
import { DropzoneIcon } from './DropzoneIcon';
import { getDashboardStyles } from './DropzoneBorder';

interface IDropzoneWrapperProps extends DropzoneRootProps {
    isDragActive: boolean;
    disabled?: boolean;
}

export const DropzoneWrapper = styled.div<IDropzoneWrapperProps>`
    ${({ theme, isDragActive, disabled }) => css`
        display: flex;
        position: relative;
        flex-wrap: nowrap;
        padding: 12px 22px 12px 16px;
        min-height: 98px;
        cursor: pointer;
        width: 100%;
        transition: all 0.2s;
        outline: none !important;
        ${isDragActive &&
        css`
            background-color: ${theme.color.primary[10]};

            ${DropzoneText} {
                text-decoration: underline;
            }
        `}
        ${DropzoneIcon} {
            margin-right: 8px;
        }

        &:hover {
            ${DropzoneText} {
                text-decoration: underline;
            }
        }
        ${disabled &&
        css`
            cursor: initial;

            ${DropzoneText} {
                color: ${theme.color.neutral[30]};
                text-decoration: none !important;
            }
        `}
        ${getDashboardStyles(theme, isDragActive, disabled)}
    `}
`;

DropzoneWrapper.defaultProps = {
    theme: DEFAULT_THEME,
};
