import type { ITheme } from '../common';

import { COLOR } from './color';
import { SHADOW } from './shadow';

export const LIGHT_THEME: ITheme = {
    id: 'light',
    name: 'Light',
    color: COLOR,
    shadow: SHADOW,
};
