import React from 'react';

import { ReactComponent as BarsLight } from '../../svg/light/bars.svg';
import { ReactComponent as BarsRegular } from '../../svg/regular/bars.svg';
import { ReactComponent as BarsSolid } from '../../svg/solid/bars.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: BarsLight,
    regular: BarsRegular,
    solid: BarsSolid,
};

export const Bars: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
