import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link as ReactLink, LinkProps } from 'react-router-dom';

import { DEFAULT_THEME } from '../../common';

import { CaptionXs } from './CaptionXs';
import { CaptionXl } from './CaptionXl';
import { SIZES, LINK_ICON_PADDING } from './constants';
import { getColors } from './utils';
import type { LinkKind, LinkSize, LinkIconPosition } from './constants';

interface ILinkComponentProps {
    kind: LinkKind;
    size: LinkSize;
    iconPosition?: LinkIconPosition;
    inverse: boolean;
    disabled: boolean;
}

const styles = css<ILinkComponentProps>`
    outline: none;
    position: relative;
    display: inline;
    box-sizing: border-box;
    text-decoration: none;
    ${({ size }) => css`
        height: ${SIZES[size]}px;
    `}

    ${({ theme, kind, iconPosition, inverse, disabled }) =>
        disabled
            ? css`
                  pointer-events: none;
                  cursor: default;
                  color: ${inverse ? theme.color.neutral[50] : theme.color.neutral[30]};
                  svg {
                      fill: ${inverse ? theme.color.neutral[50] : theme.color.neutral[30]};
                  }
              `
            : css`
                  cursor: pointer;
                  color: ${getColors(theme, kind, inverse).color};
                  svg {
                      fill: ${getColors(theme, kind, inverse).fill};
                  }

                  &:hover,
                  &:active {
                      color: ${getColors(theme, kind, inverse).hover.color};
                      ${!iconPosition
                          ? css`
                                border-bottom: 1px solid
                                    ${getColors(theme, kind, inverse).hover.color};
                            `
                          : ''}
                      svg {
                          fill: ${getColors(theme, kind, inverse).hover.fill};
                      }
                  }

                  &:focus {
                      color: ${getColors(theme, kind, inverse).focus.color};
                      svg {
                          fill: ${getColors(theme, kind, inverse).focus.fill};
                      }
                  }
              `}

        ${CaptionXs}, ${CaptionXl} {
        display: inline;
        ${({ iconPosition }) => css`
            padding-left: ${iconPosition === 'left' ? LINK_ICON_PADDING : 0};
            padding-right: ${iconPosition === 'right' ? LINK_ICON_PADDING : 0};
        `}
    }
`;

const LinkComponentReactX = styled(ReactLink)<ILinkComponentProps & LinkProps>`
    ${styles}
`;

const LinkComponentAnchorX = styled.a<
    ILinkComponentProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>`
    ${styles}
`;

LinkComponentReactX.defaultProps = {
    theme: DEFAULT_THEME,
};

LinkComponentAnchorX.defaultProps = {
    theme: DEFAULT_THEME,
};

export const LinkComponentReact: FC<ILinkComponentProps & LinkProps> = (props) => {
    return <LinkComponentReactX {...props} />;
};

export const LinkComponentAnchor: FC<
    ILinkComponentProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = (props) => {
    return <LinkComponentAnchorX {...props} />;
};
