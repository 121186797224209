import React from 'react';

import { ReactComponent as TimesLight } from '../../svg/light/times.svg';
import { ReactComponent as TimesCircleLight } from '../../svg/light/times-circle.svg';
import { ReactComponent as TimesSquareLight } from '../../svg/light/times-square.svg';
import { ReactComponent as TimesRegular } from '../../svg/regular/times.svg';
import { ReactComponent as TimesCircleRegular } from '../../svg/regular/times-circle.svg';
import { ReactComponent as TimesSquareRegular } from '../../svg/regular/times-square.svg';
import { ReactComponent as TimesSolid } from '../../svg/solid/times.svg';
import { ReactComponent as TimesCircleSolid } from '../../svg/solid/times-circle.svg';
import { ReactComponent as TimesSquareSolid } from '../../svg/solid/times-square.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: TimesLight,
        regular: TimesRegular,
        solid: TimesSolid,
    },
    circle: {
        light: TimesCircleLight,
        regular: TimesCircleRegular,
        solid: TimesCircleSolid,
    },
    square: {
        light: TimesSquareLight,
        regular: TimesSquareRegular,
        solid: TimesSquareSolid,
    },
};

export const Times: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
