import styled, { css } from 'styled-components';

import { BORDER_RADIUS, MENU_WRAPPER_Z_INDEX } from '../../constants';
import { DEFAULT_THEME } from '../../../../../common';

export interface IMenuWrapperProps {
    width: string | number;
    marginTop: string | number;
}

const geMarginTop = (marginTop: string | number) => {
    return marginTop ? marginTop : `5px`;
};

export const MenuWrapper = styled.div<IMenuWrapperProps>`
    position: absolute;
    top: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: ${MENU_WRAPPER_Z_INDEX};
    ${({ width, marginTop, theme }) => css`
        width: ${typeof width === 'number' ? `${width}px` : width};
        margin-top: ${typeof marginTop === 'number'
            ? `${geMarginTop(marginTop)}px`
            : geMarginTop(marginTop)};
        border-radius: ${BORDER_RADIUS}px;
        background-color: ${theme.color.neutral[0]};
        box-shadow: ${theme.shadow[15]};
    `}
`;

MenuWrapper.defaultProps = {
    theme: DEFAULT_THEME,
};
