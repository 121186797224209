import React from 'react';

import { ReactComponent as ClockLight } from '../../svg/light/clock.svg';
import { ReactComponent as ClockRegular } from '../../svg/regular/clock.svg';
import { ReactComponent as ClockSolid } from '../../svg/solid/clock.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: ClockLight,
    regular: ClockRegular,
    solid: ClockSolid,
};

export const Clock: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
