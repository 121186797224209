import React, { useState, ReactNode, FC } from 'react';
import { Question } from '@legex/icons';

import { createInformerHOC } from '../../../hocs/InformerHOC';
import { Input } from '../BaseField';
import { getIconSize, IInputDefaultProps } from '../common';

import { StyledQuestionSolidIcon } from './StyledQuestionSolidIcon';

export interface IInputInformer extends IInputDefaultProps {
    /** Значение инпута */
    value?: string;
    /** Текст информера */
    message?: string;
    /** Дополнительная иконка */
    icon?: ReactNode;
}

export const InputInformer: FC<IInputInformer> = ({
    value = '',
    disabled,
    size = 'xl',
    message = '',
    ...props
}) => {
    const [visible, setVisible] = useState(false);

    const iconSize = getIconSize(size);
    const IconWrapper = ({ onClick }: { onClick: () => void }): JSX.Element => (
        <StyledQuestionSolidIcon active={visible} disabled={disabled} onMouseDown={onClick}>
            <Question width={iconSize} height={iconSize} weight="solid" />
        </StyledQuestionSolidIcon>
    );

    const ButtonWithInformer = createInformerHOC(IconWrapper);
    const handleRequestHide = () => {
        !disabled && setVisible(false);
    };

    const handleClick = () => {
        !disabled && setVisible(true);
    };

    const iconServices = (
        <ButtonWithInformer
            offsetInformer={16}
            informerVisible={!disabled ? visible : false}
            size={'xs'}
            onRequestHide={handleRequestHide}
            onClick={handleClick}
            message={message}
        />
    );

    return (
        <Input
            size={size}
            disabled={disabled}
            iconServices={iconServices}
            activeInformer={visible}
            value={value}
            {...props}
        />
    );
};
