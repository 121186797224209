import React from 'react';

import { ReactComponent as PaperclipLight } from '../../svg/light/paperclip.svg';
import { ReactComponent as PaperclipRegular } from '../../svg/regular/paperclip.svg';
import { ReactComponent as PaperclipSolid } from '../../svg/solid/paperclip.svg';
import { ICommonIconResolver, IIcon, resolveIcon } from '../../common';

const iconResolver: ICommonIconResolver = {
    light: PaperclipLight,
    regular: PaperclipRegular,
    solid: PaperclipSolid,
};

export const Attach: IIcon = (props) => {
    return React.createElement(resolveIcon(props, iconResolver), props);
};
