import styled, { css } from 'styled-components';
import { size as Size } from 'polished';

import { DEFAULT_THEME } from '../../common';

import {
    BORDER_RADIUS_XL,
    BORDER_RADIUS_XS,
    INNER_CIRCLE_SIZE_XL,
    INNER_CIRCLE_SIZE_XS,
    LABEL_MARGIN,
    SWITCH_BORDER_WIDTH,
    SWITCH_HEIGHT_XL,
    SWITCH_HEIGHT_XS,
    SWITCH_WIDTH_XL,
    SWITCH_WIDTH_XS,
} from './constants';

export type LabelPosition = 'right' | 'left';
export type SwitchSize = 'xl' | 'xs';

interface ISwitchSliderProps {
    checked: boolean;
    disabled: boolean;
    labelPosition: LabelPosition;
    size: SwitchSize;
}

export const SwitchSlider = styled.div<ISwitchSliderProps>`
    object-fit: contain;
    position: relative;
    box-sizing: content-box !important;
    transition: 0.2s;
    border: ${SWITCH_BORDER_WIDTH}px solid ${({ theme, checked, disabled }) =>
    theme.color[checked ? 'primary' : 'neutral'][disabled ? 30 : 50]};
    background-color: transparent;

    ${({ size, labelPosition }) => css`
        ${size === 'xs'
            ? Size(SWITCH_HEIGHT_XS, SWITCH_WIDTH_XS)
            : Size(SWITCH_HEIGHT_XL, SWITCH_WIDTH_XL)}
        border-radius: ${size === 'xs' ? BORDER_RADIUS_XS : BORDER_RADIUS_XL}px;
        ${labelPosition === 'right'
            ? css`
                  margin-right: ${LABEL_MARGIN}px;
              `
            : css`
                  margin-left: ${LABEL_MARGIN}px;
              `}
    `}

    ${({ checked, disabled, theme }) =>
        !disabled &&
        css`
            &:hover {
                border-color: ${checked ? theme.color.primary[60] : theme.color.neutral[60]};
                &:before {
                    border-color: ${checked ? theme.color.primary[60] : theme.color.neutral[60]};
                }
            }
        `};

    &:before {
        content: '';
        position: relative;
        display: inline-block;
        transition: 0.2s;
        box-sizing: border-box;
        left: ${SWITCH_BORDER_WIDTH + 2}px;

        ${({ size, theme }) => css`
            ${size === 'xs' ? Size(INNER_CIRCLE_SIZE_XS) : Size(INNER_CIRCLE_SIZE_XL)}
            background-color: ${theme.color.neutral[0]};
            ${size === 'xs'
                ? css`
                      bottom: ${Math.ceil(
                          (SWITCH_HEIGHT_XS - SWITCH_BORDER_WIDTH - INNER_CIRCLE_SIZE_XS) / 2
                      )}px;
                  `
                : css`
                      top: ${Math.ceil(
                          (SWITCH_HEIGHT_XL - SWITCH_BORDER_WIDTH - INNER_CIRCLE_SIZE_XL) / 2
                      )}px;
                  `}
        `}
        border-radius: 50%;
        background-color: ${({ checked, disabled, theme }) =>
            theme.color[checked ? 'primary' : 'neutral'][disabled ? 30 : 50]};
        
`;

SwitchSlider.defaultProps = {
    theme: DEFAULT_THEME,
};
