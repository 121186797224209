import React from 'react';

import { ReactComponent as ChevronDownLight } from '../../svg/light/chevron-down.svg';
import { ReactComponent as ChevronDownCircleLight } from '../../svg/light/chevron-circle-down.svg';
import { ReactComponent as ChevronDownSquareLight } from '../../svg/light/chevron-square-down.svg';
import { ReactComponent as ChevronDownRegular } from '../../svg/regular/chevron-down.svg';
import { ReactComponent as ChevronDownCircleRegular } from '../../svg/regular/chevron-circle-down.svg';
import { ReactComponent as ChevronDownSquareRegular } from '../../svg/regular/chevron-square-down.svg';
import { ReactComponent as ChevronDownSolid } from '../../svg/solid/chevron-down.svg';
import { ReactComponent as ChevronDownCircleSolid } from '../../svg/solid/chevron-circle-down.svg';
import { ReactComponent as ChevronDownSquareSolid } from '../../svg/solid/chevron-square-down.svg';
import { IIconWrapped, IWrappedIconResolver, resolveWrappedIcon } from '../../common';

const iconResolver: IWrappedIconResolver = {
    none: {
        light: ChevronDownLight,
        regular: ChevronDownRegular,
        solid: ChevronDownSolid,
    },
    circle: {
        light: ChevronDownCircleLight,
        regular: ChevronDownCircleRegular,
        solid: ChevronDownCircleSolid,
    },
    square: {
        light: ChevronDownSquareLight,
        regular: ChevronDownSquareRegular,
        solid: ChevronDownSquareSolid,
    },
};

export const ChevronDown: IIconWrapped = (props) => {
    return React.createElement(resolveWrappedIcon(props, iconResolver), props);
};
