import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

export const DatePickerComponent = styled.div<{ width: string | number }>`
    width: ${({ width }) =>
        typeof width === 'number'
            ? css`
                  ${width}px
              `
            : width};
    position: relative;
`;

DatePickerComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
