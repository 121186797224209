export const DEFAULT_SELECT_RANGE_START = {
    hours: '00',
    minutes: '00',
};

export const DEFAULT_SELECT_RANGE_END = {
    hours: '23',
    minutes: '30',
};

export const ADDITIONAL_MESSAGE = 'Не верный формат времени';
export const DEFAULT_WIDTH = '100%';
export const MASK = '99 : 99';
