import styled from 'styled-components';

import { Z_INDEXES } from '../../common';

const SIZE = {
    xl: 48,
    xs: 18,
};

export type Size = 'xl' | 'xs';

export const SpinnerIconWrapper = styled.div<{ size: Size }>`
    height: ${({ size }) => SIZE[size]}px;
    width: ${({ size }) => SIZE[size]}px;
    z-index: ${Z_INDEXES.OVERLAY_SPINNER};
`;
