import type { ITheme } from '../../themes';

import type { Size } from './ButtonComponent';

const BORDER_WIDTH = 1;
const PADDING_COMPENSATION = 2;

export const BUTTON_SIZE = {
    xl: 40,
    xs: 30,
};

export const calculatePadding = ({
    multipleChildren,
    rightIcon,
}: {
    multipleChildren: boolean;
    rightIcon: boolean;
    size: Size;
    theme: ITheme;
}) => {
    const padding = 12 - BORDER_WIDTH;

    if (!multipleChildren) return `0 ${padding}px`;

    const left = padding - (rightIcon ? 0 : PADDING_COMPENSATION);
    const right = padding - (rightIcon ? PADDING_COMPENSATION : 0);

    return `0 ${right}px 0 ${left}px`;
};

export const getWidth = ({
    fullWidth,
    onlyIcon,
    size,
}: {
    fullWidth: boolean;
    onlyIcon: boolean;
    size: Size;
}) => {
    if (onlyIcon) return `${BUTTON_SIZE[size]}px`;
    if (fullWidth) return '100%';
    return 'auto';
};
