import { ITheme } from '../../themes';

export type TabsSize = 'xl' | 'xs';

export const UNDERLINE_HEIGHT = '2px';
export const TAB_HEIGHT_SMALL = '40px';
export const TAB_HEIGHT_BIG = '48px';
export const TAB_PADDING = '20px';
export const TAB_PADDING_RIGHT_WITH_BADGE = '8px';
export const TAB_PADDING_LEFT_WITH_ICON = '8px';

export const getMargin = (size: TabsSize) => (size === 'xl' ? '32px' : '24px');
export const getMenuMargin = (size: TabsSize) => {
    switch (size) {
        case 'xl':
            return '12px 12px 12px 44px';
        case 'xs':
            return '8px 8px 8px 32px';
    }
};
