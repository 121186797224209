import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { getBackgroundColor, getTextColor } from './utils';
import {
    BORDER_RADIUS,
    HEIGHT_XL,
    HEIGHT_XS,
    HORIZONTAL_PADDING_XL,
    HORIZONTAL_PADDING_XS,
    MIN_WIDTH_XL,
    MIN_WIDTH_XS,
} from './constants';

export type Kind =
    | 'neutral-grey'
    | 'neutral-dark'
    | 'primary'
    | 'warning'
    | 'danger'
    | 'success'
    | 'neutral-light'
    | 'neutral-light-disabled'
    | 'neutral-light-inactive'
    | 'neutral-white'
    | 'neutral-white-disabled'
    | 'neutral-white-inactive';

export type Size = 'xl' | 'xs';

interface IBadgeComponentProps {
    kind: Kind;
    size: Size;
}

export const BadgeComponent = styled.div<IBadgeComponentProps>`
    ${({ kind, size, theme }) => css`
        align-items: center;
        background-color: ${getBackgroundColor(kind, theme)};
        border-radius: ${BORDER_RADIUS}px;
        color: ${getTextColor(kind, theme)};
        display: flex;
        height: ${size === 'xl' ? HEIGHT_XL : HEIGHT_XS}px;
        justify-content: center;
        min-width: ${size === 'xl' ? MIN_WIDTH_XL : MIN_WIDTH_XS}px;
        padding: 0 ${size === 'xl' ? HORIZONTAL_PADDING_XL : HORIZONTAL_PADDING_XS}px;
        user-select: none;
    `};
`;

BadgeComponent.defaultProps = {
    theme: DEFAULT_THEME,
};
