import React from 'react';
import type { FC, MouseEvent, ReactNode } from 'react';

import { ButtonText } from '../../Typography';

import { IconLayout } from './IconLayout';
import { ButtonComponent } from './ButtonComponent';
import type { ButtonBasicProps, Color, Size } from './ButtonComponent';

type Type = 'button' | 'submit' | 'reset';

export interface IButtonProps extends ButtonBasicProps {
    /** Элементы содержимого */
    children?: ReactNode;
    /** Имя класса для переопределения стилей */
    className?: string;
    /** data-test-id атрибут для тестирования компонента */
    dataTestId?: string;
    /** Отключение кнопки */
    disabled?: boolean;
    /** Растягивает кнопку на всю ширину контейнера */
    fullWidth?: boolean;
    /** Внешний вид кнопки */
    color?: Color;
    /** Размер кнопки */
    size?: Size;
    /** Тип кнопки */
    type?: Type;
    /** Коллбэк на клик */
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const Button: FC<IButtonProps> = ({
    children,
    className,
    dataTestId,
    disabled = false,
    fullWidth = false,
    color = 'primary',
    size = 'xl',
    type = 'button',
    onClick,
    ...props
}) => {
    const isObject = (element: ReactNode) => typeof element === 'object';

    const onlyIcon = Boolean(children) && !Array.isArray(children) && isObject(children);
    const rightIcon = Array.isArray(children) && isObject(children[children.length - 1]);

    const handleFocusPrevent = (event: MouseEvent<HTMLButtonElement>) => event.preventDefault();

    const renderChild = (child: ReactNode, key?: number) =>
        isObject(child) ? (
            <IconLayout key={key} onlyIcon={onlyIcon} rightIcon={rightIcon}>
                {child}
            </IconLayout>
        ) : (
            <ButtonText key={key}>{(child as string).toLocaleUpperCase()}</ButtonText>
        );

    const renderChildren = () =>
        Array.isArray(children) ? children.map(renderChild) : renderChild(children);

    return (
        <ButtonComponent
            {...props}
            className={className}
            data-test-id={dataTestId}
            disabled={disabled}
            fullWidth={fullWidth}
            color={color}
            multipleChildren={Array.isArray(children)}
            onlyIcon={onlyIcon}
            rightIcon={rightIcon}
            size={size}
            type={type}
            onClick={onClick}
            onMouseDown={handleFocusPrevent}
        >
            {children && renderChildren()}
        </ButtonComponent>
    );
};
