import styled, { css } from 'styled-components';

import { DEFAULT_THEME, Z_INDEXES } from '../../common';

import { getPointerPositionStyle } from './utils';
import { BORDER_RADIUS, POINTER_HEIGHT, TOOLTIP_PADDING } from './constants';

import type { ITooltipHOCProps } from './index';

interface ITooltipProps {
    pointerDirection: ITooltipHOCProps['direction'];
    position: {
        posX: number;
        posY: number;
    };
    showTooltip: boolean;
}

export const Tooltip = styled.div<ITooltipProps>`
    display: flex;
    align-items: center;
    position: fixed;
    padding: ${TOOLTIP_PADDING}px;
    white-space: nowrap;
    z-index: ${Z_INDEXES.TOOLTIP};
    border-radius: ${BORDER_RADIUS}px;
    transition: opacity 0.25s ease;

    ${({ theme, showTooltip, pointerDirection, position: { posX, posY } }) => css`
        box-shadow: 0 0 15px 0 ${theme.color.opacity.black20};
        background: ${theme.color.neutral[0]};
        left: ${posX}px;
        top: ${posY}px;
        color: ${theme.color.neutral[80]};
        visibility: ${showTooltip ? 'visible' : 'hidden'};
        opacity: ${showTooltip ? 1 : 0};

        &::after {
            border-color: transparent;
            border-bottom-color: ${theme.color.neutral[0]};
            border-style: solid;
            border-width: 0 ${POINTER_HEIGHT}px ${POINTER_HEIGHT}px ${POINTER_HEIGHT}px;
            content: '';
            height: 0;
            position: absolute;
            width: 0;
            ${getPointerPositionStyle(pointerDirection)};
        }
    `}
`;

Tooltip.defaultProps = {
    theme: DEFAULT_THEME,
};
