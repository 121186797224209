export type Direction = 'bottom' | 'top';
export type Align = 'left' | 'center' | 'right';
export type Size = 'xl' | 'xs';

export const SIZES: { [key in Size]: number } = {
    xl: 488,
    xs: 280,
};

export const INFORMER_PADDING = '12px 52px 12px 16px';
export const INFORMER_MARGIN = 4;

export const ICON_CLOSE_RIGHT = 16;
export const ICON_CLOSE_TOP = 12;

export const POINTER_HEIGHT = 8;
export const POINTER_WIDTH = 14;
export const POINTER_MARGIN = 18;

export const SAFE_SPACE = 16;
export const BORDER_RADIUS = 3;
