import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../../../common';
import { Text2 } from '../../../../Typography';

export const ErrorText = styled(Text2)`
    ${({ theme }) => css`
        color: ${theme.color.danger[60]};
        margin-top: 8px;
    `}
`;

ErrorText.defaultProps = {
    theme: DEFAULT_THEME,
};
