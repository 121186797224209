import styled, { css } from 'styled-components';

import { DEFAULT_THEME, Z_INDEXES } from '../../common';

import { IconClose } from './IconClose';
import { POINTER_HEIGHT, POINTER_WIDTH, INFORMER_PADDING, BORDER_RADIUS } from './constants';
import { getPointerDirectionStyle, getPointerAlignStyle } from './utils';
import type { Direction, Align } from './constants';

interface IInformer {
    pointerDirection: Direction;
    pointerAlign: Align;
    width: number;
    position: {
        posX: number;
        posY: number;
    };
    visible: boolean;
    offsetInformer?: number;
}

export const Informer = styled.div<IInformer>`
    align-items: center;
    background: ${({ theme }) => theme.color.neutral[0]};
    box-shadow: ${({ theme }) => theme.shadow[15]};
    color: ${({ theme }) => theme.color.neutral[80]};
    border-radius: ${BORDER_RADIUS}px;
    box-sizing: border-box;
    display: flex;
    width: ${({ width }) => width}px;
    padding: ${INFORMER_PADDING};
    position: fixed;
    left: ${({ position: { posX } }) => posX}px;
    top: ${({ position: { posY }, offsetInformer }) =>
        offsetInformer ? posY + offsetInformer : posY}px;

    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    z-index: ${Z_INDEXES.INFORMER};

    &::after {
        border-color: transparent transparent ${({ theme }) => theme.color.neutral[0]} transparent;
        border-style: solid;
        border-width: 0 ${POINTER_HEIGHT}px ${POINTER_HEIGHT}px ${POINTER_HEIGHT}px;
        content: '';
        height: 0;
        position: absolute;
        width: 0;
        ${({ pointerDirection }) => getPointerDirectionStyle(pointerDirection, POINTER_HEIGHT)};

        ${({ pointerAlign }) => getPointerAlignStyle(pointerAlign, POINTER_WIDTH)};
    }
`;

Informer.defaultProps = {
    theme: DEFAULT_THEME,
};
