import styled, { css } from 'styled-components';

import { Text2 } from '../../Typography';
import { DEFAULT_THEME } from '../../common';

import { getLabelColorByStatus } from './utils-style';
import type { IThemeAndStatus } from './interfaces';

export const LimitLabel = styled(Text2)<IThemeAndStatus>`
    ${({ theme, $status = 'default' }) => css`
        position: absolute;
        right: 0;
        color: ${getLabelColorByStatus(theme, $status)};
    `}
`;

LimitLabel.defaultProps = {
    theme: DEFAULT_THEME,
};
