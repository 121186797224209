import styled, { css } from 'styled-components';

import type { Size } from '../../common';

import { BORDER_RADIUS, DROPDOWN_OFFSET, SELECT_CONTAINER_PADDING_LEFT } from './constants';

export interface IStyledMenu {
    size: Size;
    width: number;
}

export const StyledMenu = styled.div<IStyledMenu>`
    ${({ theme, width, size }) => css`
        position: absolute;
        box-sizing: border-box;
        top: ${DROPDOWN_OFFSET[size]}px;
        left: -${SELECT_CONTAINER_PADDING_LEFT[size]}px;
        width: ${width}px;
        border-radius: 0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px;
        background-color: ${theme.color.neutral[0]};
        box-shadow: ${theme.shadow[15]};
    `}
`;
