import styled from 'styled-components';
import { components } from 'react-select';
import { FC } from 'react';

export const MenuListWrapper: FC = styled(components.MenuList)`
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    position: relative;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    overflow: visible !important;
    overflow-y: auto !important;
`;
