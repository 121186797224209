import styled, { css } from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { getKindStyle } from './getKindStyle';
import type { Size } from './ButtonGroupComponent';
import { BORDER_RADIUS, BORDER_WIDTH, PADDING_XL, PADDING_XS } from './constants';

const PADDING_HORIZONTAL = {
    xl: PADDING_XL,
    xs: PADDING_XS,
};

export type Kind = 'solid' | 'outlined';

interface IButtonProps {
    active: boolean;
    disabled: boolean;
    kind: Kind;
    size: Size;
}

export const Button = styled.div<IButtonProps>`
    ${({ active, disabled, kind, size, theme }) => css`
        align-items: center;
        border-radius: ${BORDER_RADIUS}px;
        border-style: solid;
        border-width: ${BORDER_WIDTH}px;
        box-sizing: border-box;
        color: ${disabled ? theme.color.neutral[30] : theme.color.neutral[50]};
        cursor: ${disabled ? 'default' : 'pointer'};
        display: flex;
        padding: 0 ${PADDING_HORIZONTAL[size] - BORDER_WIDTH}px;
        position: relative;

        & div svg {
            fill: ${disabled ? theme.color.neutral[30] : theme.color.neutral[50]};
        }

        &:focus {
            outline: none;

            &::after {
                border-radius: ${BORDER_RADIUS}px;
                border-color: ${theme.color.primary[30]};
                border-style: solid;
                border-width: 2px;
                bottom: 2px;
                content: '';
                display: block;
                left: 2px;
                position: absolute;
                right: 2px;
                top: 2px;
            }
        }

        ${getKindStyle({ active, disabled, kind, theme })};
    `};
`;

Button.defaultProps = {
    theme: DEFAULT_THEME,
};
