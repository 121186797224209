import { css } from 'styled-components';
import { transparentize } from 'polished';

import type { ITheme } from '../../../themes';
import { BOX_SHADOW } from '../constants';

export const secondary = (theme: ITheme) => css`
    background-color: ${theme.color.neutral[0]};
    border-color: ${theme.color.primary[60]};
    color: ${theme.color.primary[60]};

    & div svg {
        fill: ${theme.color.primary[60]};
    }

    &:focus {
        &::after {
            border-color: ${theme.color.primary[60]};
        }
    }

    &:hover {
        box-shadow: ${BOX_SHADOW} ${transparentize(0.4, theme.color.primary[50])};

        & div svg {
            fill: ${theme.color.primary[60]};
        }
    }

    &:active {
        box-shadow: none;
        background-color: ${theme.color.neutral[10]};
        border-color: ${theme.color.primary[60]};
        color: ${theme.color.primary[60]};

        & div svg {
            fill: ${theme.color.primary[60]};
        }
    }

    &:disabled {
        box-shadow: none;
        border-color: ${theme.color.neutral[30]};
        color: ${theme.color.neutral[30]};

        & div svg {
            fill: ${theme.color.neutral[30]};
        }
    }
`;
