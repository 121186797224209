import styled from 'styled-components';

import { DEFAULT_THEME } from '../../common';

import { BORDER_RADIUS, PROGRESS_SPEED } from './constants';

export const Progress = styled.div<{ error: boolean; value: number }>`
    background: ${({ error, theme }) => (error ? theme.color.danger[50] : theme.color.primary[50])};
    border-radius: ${BORDER_RADIUS}px;
    height: 100%;
    transition: width ${PROGRESS_SPEED} ease-in-out;
    width: ${({ value }) => value}%;
`;

Progress.defaultProps = {
    theme: DEFAULT_THEME,
};
